<template>

<main id="tt-pageContent" class="tt-offset-none">
    <div class="container">
        <div class="tt-loginpages-wrapper">
            <div class="tt-loginpages">
                <a href="javascript:;" class="tt-block-title">
                    <img :src="getMainLogo" alt=""  style="max-width:13%">
                    <div class="tt-title">
                        Welcome to Rocb-Europe Forum
                    </div>
                    <div class="tt-description">
                       Log into your account to unlock true power of community.
                    </div>
                </a>
                <form class="form-default"  @submit.prevent="submit" @keydown="loginForm.errors.clear($event.target.name)">
                    <div class="form-group">
                        <label for="loginUserName">Username</label>
                        <input type="text" name="email" :placeholder="trans('auth.email')" v-model="loginForm.email"  class="form-control" id="loginUserName">
                        <show-error :form-name="loginForm" prop-name="email"></show-error>
                    </div>
                    <div class="form-group">
                        <label for="loginUserPassword">Password</label>
                        <input type="password" name="password" class="form-control" id="loginUserPassword" :placeholder="trans('auth.password')" v-model="loginForm.password">
                        <show-error :form-name="loginForm" prop-name="password"></show-error>
                    </div>
                    <div class="g-recaptcha" v-if="getConfig('recaptcha') && getConfig('login_recaptcha')" :data-sitekey="getConfig('recaptcha_key')"></div>
                    <div class="row">
                        <div class="col">
                            <div class="form-group">
                                <div class="checkbox-group">
                                    <input type="checkbox" id="settingsCheckBox01" name="checkbox">
                                    <label for="settingsCheckBox01">
                                        <span class="check"></span>
                                        <span class="box"></span>
                                        <span class="tt-text">Remember me</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="col ml-auto text-right">
                              <p v-if="getConfig('reset_password')">{{trans('auth.forgot_your_password?')}} <router-link to="/password" class="text-info m-l-5"><b>{{trans('auth.reset_here!')}}</b></router-link></p>
                                <p v-if="getConfig('registration')">{{trans('auth.create_account?')}} <router-link to="/register" class="text-info m-l-5"><b>{{trans('auth.sign_up')}}</b></router-link></p>
                        </div>
                    </div>
                    <div class="form-group">
                        <button type="submit" class="btn btn-secondary btn-block">{{trans('auth.sign_in')}}</button>
                    </div>
                    <p  v-if="getConfig('social_login')">Or login with social network</p>
                    <div class="row"  v-if="getConfig('social_login')">
                        <a v-for="provider in social_login_providers" v-if="getConfig(provider+'_login')" :href="`/auth/social/${provider}`" :class="['btn','btn-'+provider,'m-r-5','no-hover']" v-tooltip="trans('auth.login_with',{type:provider})"> <i :class="['fab','fa-'+provider]"></i></a>
                    </div>
                   
                </form>
                <guest-footer></guest-footer>
            </div>
        </div>
    </div>
</main>
</template>

<script>
    import guestFooter from '../../layouts/guest-footer.vue'

    export default {
        data() {
            return {
                loginForm: new Form({
                    email: '',
                    password: ''
                }),
                social_login_providers: []
            }
        },
        components: {
            guestFooter
        },
        mounted(){
            if(helper.getConfig('social_login'))
            axios.get('/api/configuration/variable?type=social_login')
                .then(response => response.data)
                .then(response => {
                    this.social_login_providers = response.social_login_providers;
                })
                .catch(error => {
                    helper.showDataErrorMsg(error);
                });
        },
        methods: {
            submit(){
                this.loginForm.post('/api/auth/login')
                    .then(response =>  {
                        localStorage.setItem('auth_token',response.token);
                        axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('auth_token');
                        this.$store.dispatch('setAuthStatus');
                        this.$store.dispatch('setLastActivity');
                        toastr.success(response.message);

                        if(helper.getConfig('two_factor_security') && response.two_factor_code){
                            this.$store.dispatch('setTwoFactorCode',response.two_factor_code);
                            this.$router.push('/auth/security');
                        }
                        else {
                            var redirect_path = response.reload ? '/home?reload=1' : '/home';
                            this.$store.dispatch('resetTwoFactorCode');
                            this.$router.push(redirect_path);
                        }
                    }).catch(error => {
                        helper.showErrorMsg(error);
                    });
            },
            loginAsAdmin(){
                this.loginForm.email = 'john.doe@example.com';
                this.loginForm.password = 'password';
                this.submit();
            },
            loginAsUser(){
                this.loginForm.email = 'marry.jen@example.com';
                this.loginForm.password = 'password';
                this.submit();
            },
            getConfig(config){
                return helper.getConfig(config);
            }
        },
        computed: {
            getMainLogo(){
                if(helper.getConfig('main_logo'))
                    return '/'+helper.getConfig('main_logo');
                else
                    return '/images/default_main_logo.png';
            },
            getSidebarLogo(){
                if(helper.getConfig('sidebar_logo'))
                    return '/'+helper.getConfig('sidebar_logo');
                else
                    return '/images/default_sidebar_logo.png';
            }
        }
    }
</script>
