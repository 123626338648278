var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main",
    { staticClass: "tt-offset-none", attrs: { id: "tt-pageContent" } },
    [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "tt-loginpages-wrapper" }, [
          _c(
            "div",
            { staticClass: "tt-loginpages" },
            [
              _c(
                "a",
                {
                  staticClass: "tt-block-title",
                  attrs: { href: "javascript:;" }
                },
                [
                  _c("img", {
                    staticStyle: { "max-width": "13%" },
                    attrs: { src: _vm.getMainLogo, alt: "" }
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "tt-title" }, [
                    _vm._v(
                      "\r\n                        Welcome to Rocb-Europe Forum\r\n                    "
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "tt-description" }, [
                    _vm._v(
                      "\r\n                       Log into your account to unlock true power of community.\r\n                    "
                    )
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "form",
                {
                  staticClass: "form-default",
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.submit($event)
                    },
                    keydown: function($event) {
                      return _vm.loginForm.errors.clear($event.target.name)
                    }
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("label", { attrs: { for: "loginUserName" } }, [
                        _vm._v("Username")
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.loginForm.email,
                            expression: "loginForm.email"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          name: "email",
                          placeholder: _vm.trans("auth.email"),
                          id: "loginUserName"
                        },
                        domProps: { value: _vm.loginForm.email },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.loginForm,
                              "email",
                              $event.target.value
                            )
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("show-error", {
                        attrs: {
                          "form-name": _vm.loginForm,
                          "prop-name": "email"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("label", { attrs: { for: "loginUserPassword" } }, [
                        _vm._v("Password")
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.loginForm.password,
                            expression: "loginForm.password"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "password",
                          name: "password",
                          id: "loginUserPassword",
                          placeholder: _vm.trans("auth.password")
                        },
                        domProps: { value: _vm.loginForm.password },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.loginForm,
                              "password",
                              $event.target.value
                            )
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("show-error", {
                        attrs: {
                          "form-name": _vm.loginForm,
                          "prop-name": "password"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.getConfig("recaptcha") && _vm.getConfig("login_recaptcha")
                    ? _c("div", {
                        staticClass: "g-recaptcha",
                        attrs: {
                          "data-sitekey": _vm.getConfig("recaptcha_key")
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _vm._m(0),
                    _vm._v(" "),
                    _c("div", { staticClass: "col ml-auto text-right" }, [
                      _vm.getConfig("reset_password")
                        ? _c(
                            "p",
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.trans("auth.forgot_your_password?")
                                ) + " "
                              ),
                              _c(
                                "router-link",
                                {
                                  staticClass: "text-info m-l-5",
                                  attrs: { to: "/password" }
                                },
                                [
                                  _c("b", [
                                    _vm._v(
                                      _vm._s(_vm.trans("auth.reset_here!"))
                                    )
                                  ])
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.getConfig("registration")
                        ? _c(
                            "p",
                            [
                              _vm._v(
                                _vm._s(_vm.trans("auth.create_account?")) + " "
                              ),
                              _c(
                                "router-link",
                                {
                                  staticClass: "text-info m-l-5",
                                  attrs: { to: "/register" }
                                },
                                [
                                  _c("b", [
                                    _vm._v(_vm._s(_vm.trans("auth.sign_up")))
                                  ])
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-secondary btn-block",
                        attrs: { type: "submit" }
                      },
                      [_vm._v(_vm._s(_vm.trans("auth.sign_in")))]
                    )
                  ]),
                  _vm._v(" "),
                  _vm.getConfig("social_login")
                    ? _c("p", [_vm._v("Or login with social network")])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.getConfig("social_login")
                    ? _c(
                        "div",
                        { staticClass: "row" },
                        _vm._l(_vm.social_login_providers, function(provider) {
                          return _vm.getConfig(provider + "_login")
                            ? _c(
                                "a",
                                {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip",
                                      value: _vm.trans("auth.login_with", {
                                        type: provider
                                      }),
                                      expression:
                                        "trans('auth.login_with',{type:provider})"
                                    }
                                  ],
                                  class: [
                                    "btn",
                                    "btn-" + provider,
                                    "m-r-5",
                                    "no-hover"
                                  ],
                                  attrs: { href: "/auth/social/" + provider }
                                },
                                [_c("i", { class: ["fab", "fa-" + provider] })]
                              )
                            : _vm._e()
                        }),
                        0
                      )
                    : _vm._e()
                ]
              ),
              _vm._v(" "),
              _c("guest-footer")
            ],
            1
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col" }, [
      _c("div", { staticClass: "form-group" }, [
        _c("div", { staticClass: "checkbox-group" }, [
          _c("input", {
            attrs: {
              type: "checkbox",
              id: "settingsCheckBox01",
              name: "checkbox"
            }
          }),
          _vm._v(" "),
          _c("label", { attrs: { for: "settingsCheckBox01" } }, [
            _c("span", { staticClass: "check" }),
            _vm._v(" "),
            _c("span", { staticClass: "box" }),
            _vm._v(" "),
            _c("span", { staticClass: "tt-text" }, [_vm._v("Remember me")])
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }