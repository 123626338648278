<template>
    <div>
        <app-header></app-header> 
 
        <router-view></router-view>
        <app-footer></app-footer> 
    </div>
</template>


<script>
    import AppHeader from './header.vue'
    import AppFooter from './footer.vue'

    export default {
        data(){
            return {
                message: '',
                sidebar: helper.getConfig('user_sidebar') || helper.getConfig('sidebar')
            }
        },
        components: {
            AppHeader, AppFooter
        },
        mounted() {
            helper.notification();

           
        },
        methods: {
            miniSidebar(){ 
            },
            normalSidebar(){ 
            },
            getConfig(config){
                return helper.getConfig(config);
            }
        },
        watch: {
            sidebar(val) {
                if (val == 'mini')
                    this.miniSidebar();
                else
                    this.normalSidebar();

            }
        }
    }
</script>
