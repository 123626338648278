var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("main", { attrs: { id: "tt-pageContent" } }, [
      _c("div", { staticClass: "tt-custom-mobile-indent container" }, [
        _c("div", { staticClass: "tt-categories-title" }, [
          _c("div", { staticClass: "tt-title" }, [_vm._v("Categories")]),
          _vm._v(" "),
          _c("div", { staticClass: "tt-search" }, [
            _c("form", { staticClass: "search-wrapper" }, [
              _c("div", { staticClass: "search-form" }, [
                _c("input", {
                  staticClass: "tt-search__input",
                  attrs: { type: "text", placeholder: "Search Categories" }
                }),
                _vm._v(" "),
                _c(
                  "button",
                  { staticClass: "tt-search__btn", attrs: { type: "submit" } },
                  [
                    _c("svg", { staticClass: "tt-icon" }, [
                      _c("use", { attrs: { "xlink:href": "#icon-search" } })
                    ])
                  ]
                ),
                _vm._v(" "),
                _c("button", { staticClass: "tt-search__close" }, [
                  _c("svg", { staticClass: "tt-icon" }, [
                    _c("use", { attrs: { "xlink:href": "#icon-cancel" } })
                  ])
                ])
              ])
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "tt-categories-list" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-md-6 col-lg-4" }, [
              _c("div", { staticClass: "tt-item" }, [
                _vm._m(0),
                _vm._v(" "),
                _c("div", { staticClass: "tt-item-layout" }, [
                  _c("div", { staticClass: "innerwrapper" }, [
                    _vm._v(
                      "\n                               Lets discuss about whats happening around the world politics.\n                           "
                    )
                  ]),
                  _vm._v(" "),
                  _vm._m(1),
                  _vm._v(" "),
                  _c(
                    "a",
                    { staticClass: "tt-btn-icon", attrs: { href: "#" } },
                    [
                      _c("i", { staticClass: "tt-icon" }, [
                        _c("svg", [
                          _c("use", {
                            attrs: { "xlink:href": "#icon-favorite" }
                          })
                        ])
                      ])
                    ]
                  )
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-md-6 col-lg-4" }, [
              _c("div", { staticClass: "tt-item" }, [
                _vm._m(2),
                _vm._v(" "),
                _c("div", { staticClass: "tt-item-layout" }, [
                  _c("div", { staticClass: "tt-innerwrapper" }, [
                    _vm._v(
                      "\n                               Lets discuss about whats happening around the world politics.\n                           "
                    )
                  ]),
                  _vm._v(" "),
                  _vm._m(3),
                  _vm._v(" "),
                  _c("div", { staticClass: "tt-innerwrapper" }, [
                    _c(
                      "a",
                      { staticClass: "tt-btn-icon", attrs: { href: "#" } },
                      [
                        _c("i", { staticClass: "tt-icon" }, [
                          _c("svg", [
                            _c("use", {
                              attrs: { "xlink:href": "#icon-favorite" }
                            })
                          ])
                        ])
                      ]
                    )
                  ])
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-md-6 col-lg-4" }, [
              _c("div", { staticClass: "tt-item" }, [
                _vm._m(4),
                _vm._v(" "),
                _c("div", { staticClass: "tt-item-layout" }, [
                  _c("div", { staticClass: "tt-innerwrapper" }, [
                    _vm._v(
                      "\n                               Lets discuss about whats happening around the world politics.\n                           "
                    )
                  ]),
                  _vm._v(" "),
                  _vm._m(5),
                  _vm._v(" "),
                  _c("div", { staticClass: "tt-innerwrapper" }, [
                    _c(
                      "a",
                      { staticClass: "tt-btn-icon", attrs: { href: "#" } },
                      [
                        _c("i", { staticClass: "tt-icon" }, [
                          _c("svg", [
                            _c("use", {
                              attrs: { "xlink:href": "#icon-favorite" }
                            })
                          ])
                        ])
                      ]
                    )
                  ])
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-md-6 col-lg-4" }, [
              _c("div", { staticClass: "tt-item" }, [
                _vm._m(6),
                _vm._v(" "),
                _c("div", { staticClass: "tt-item-layout" }, [
                  _c("div", { staticClass: "tt-innerwrapper" }, [
                    _vm._v(
                      "\n                               Lets discuss about whats happening around the world politics.\n                           "
                    )
                  ]),
                  _vm._v(" "),
                  _vm._m(7),
                  _vm._v(" "),
                  _c("div", { staticClass: "tt-innerwrapper" }, [
                    _c(
                      "a",
                      { staticClass: "tt-btn-icon", attrs: { href: "#" } },
                      [
                        _c("i", { staticClass: "tt-icon" }, [
                          _c("svg", [
                            _c("use", {
                              attrs: { "xlink:href": "#icon-favorite" }
                            })
                          ])
                        ])
                      ]
                    )
                  ])
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-md-6 col-lg-4" }, [
              _c("div", { staticClass: "tt-item" }, [
                _vm._m(8),
                _vm._v(" "),
                _c("div", { staticClass: "tt-item-layout" }, [
                  _c("div", { staticClass: "tt-innerwrapper" }, [
                    _vm._v(
                      "\n                               Lets discuss about whats happening around the world politics.\n                           "
                    )
                  ]),
                  _vm._v(" "),
                  _vm._m(9),
                  _vm._v(" "),
                  _c("div", { staticClass: "tt-innerwrapper" }, [
                    _c(
                      "a",
                      { staticClass: "tt-btn-icon", attrs: { href: "#" } },
                      [
                        _c("i", { staticClass: "tt-icon" }, [
                          _c("svg", [
                            _c("use", {
                              attrs: { "xlink:href": "#icon-favorite" }
                            })
                          ])
                        ])
                      ]
                    )
                  ])
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-md-6 col-lg-4" }, [
              _c("div", { staticClass: "tt-item" }, [
                _vm._m(10),
                _vm._v(" "),
                _c("div", { staticClass: "tt-item-layout" }, [
                  _c("div", { staticClass: "tt-innerwrapper" }, [
                    _vm._v(
                      "\n                               Lets discuss about whats happening around the world politics.\n                           "
                    )
                  ]),
                  _vm._v(" "),
                  _vm._m(11),
                  _vm._v(" "),
                  _c("div", { staticClass: "tt-innerwrapper" }, [
                    _c(
                      "a",
                      { staticClass: "tt-btn-icon", attrs: { href: "#" } },
                      [
                        _c("i", { staticClass: "tt-icon" }, [
                          _c("svg", [
                            _c("use", {
                              attrs: { "xlink:href": "#icon-favorite" }
                            })
                          ])
                        ])
                      ]
                    )
                  ])
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-md-6 col-lg-4" }, [
              _c("div", { staticClass: "tt-item" }, [
                _vm._m(12),
                _vm._v(" "),
                _c("div", { staticClass: "tt-item-layout" }, [
                  _c("div", { staticClass: "tt-innerwrapper" }, [
                    _vm._v(
                      "\n                               Lets discuss about whats happening around the world politics.\n                           "
                    )
                  ]),
                  _vm._v(" "),
                  _vm._m(13),
                  _vm._v(" "),
                  _c("div", { staticClass: "tt-innerwrapper" }, [
                    _c(
                      "a",
                      { staticClass: "tt-btn-icon", attrs: { href: "#" } },
                      [
                        _c("i", { staticClass: "tt-icon" }, [
                          _c("svg", [
                            _c("use", {
                              attrs: { "xlink:href": "#icon-favorite" }
                            })
                          ])
                        ])
                      ]
                    )
                  ])
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-md-6 col-lg-4" }, [
              _c("div", { staticClass: "tt-item" }, [
                _vm._m(14),
                _vm._v(" "),
                _c("div", { staticClass: "tt-item-layout" }, [
                  _c("div", { staticClass: "tt-innerwrapper" }, [
                    _vm._v(
                      "\n                               Lets discuss about whats happening around the world politics.\n                           "
                    )
                  ]),
                  _vm._v(" "),
                  _vm._m(15),
                  _vm._v(" "),
                  _c("div", { staticClass: "tt-innerwrapper" }, [
                    _c(
                      "a",
                      { staticClass: "tt-btn-icon", attrs: { href: "#" } },
                      [
                        _c("i", { staticClass: "tt-icon" }, [
                          _c("svg", [
                            _c("use", {
                              attrs: { "xlink:href": "#icon-favorite" }
                            })
                          ])
                        ])
                      ]
                    )
                  ])
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-md-6 col-lg-4" }, [
              _c("div", { staticClass: "tt-item" }, [
                _vm._m(16),
                _vm._v(" "),
                _c("div", { staticClass: "tt-item-layout" }, [
                  _c("div", { staticClass: "tt-innerwrapper" }, [
                    _vm._v(
                      "\n                               Lets discuss about whats happening around the world politics.\n                           "
                    )
                  ]),
                  _vm._v(" "),
                  _vm._m(17),
                  _vm._v(" "),
                  _c("div", { staticClass: "tt-innerwrapper" }, [
                    _c(
                      "a",
                      { staticClass: "tt-btn-icon", attrs: { href: "#" } },
                      [
                        _c("i", { staticClass: "tt-icon" }, [
                          _c("svg", [
                            _c("use", {
                              attrs: { "xlink:href": "#icon-favorite" }
                            })
                          ])
                        ])
                      ]
                    )
                  ])
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-md-6 col-lg-4" }, [
              _c("div", { staticClass: "tt-item" }, [
                _vm._m(18),
                _vm._v(" "),
                _c("div", { staticClass: "tt-item-layout" }, [
                  _c("div", { staticClass: "tt-innerwrapper" }, [
                    _vm._v(
                      "\n                               Lets discuss about whats happening around the world politics.\n                           "
                    )
                  ]),
                  _vm._v(" "),
                  _vm._m(19),
                  _vm._v(" "),
                  _c("div", { staticClass: "tt-innerwrapper" }, [
                    _c(
                      "a",
                      { staticClass: "tt-btn-icon", attrs: { href: "#" } },
                      [
                        _c("i", { staticClass: "tt-icon" }, [
                          _c("svg", [
                            _c("use", {
                              attrs: { "xlink:href": "#icon-favorite" }
                            })
                          ])
                        ])
                      ]
                    )
                  ])
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-md-6 col-lg-4" }, [
              _c("div", { staticClass: "tt-item" }, [
                _vm._m(20),
                _vm._v(" "),
                _c("div", { staticClass: "tt-item-layout" }, [
                  _c("div", { staticClass: "tt-innerwrapper" }, [
                    _vm._v(
                      "\n                               Lets discuss about whats happening around the world politics.\n                           "
                    )
                  ]),
                  _vm._v(" "),
                  _vm._m(21),
                  _vm._v(" "),
                  _c("div", { staticClass: "tt-innerwrapper" }, [
                    _c(
                      "a",
                      { staticClass: "tt-btn-icon", attrs: { href: "#" } },
                      [
                        _c("i", { staticClass: "tt-icon" }, [
                          _c("svg", [
                            _c("use", {
                              attrs: { "xlink:href": "#icon-favorite" }
                            })
                          ])
                        ])
                      ]
                    )
                  ])
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-md-6 col-lg-4" }, [
              _c("div", { staticClass: "tt-item" }, [
                _vm._m(22),
                _vm._v(" "),
                _c("div", { staticClass: "tt-item-layout" }, [
                  _c("div", { staticClass: "tt-innerwrapper" }, [
                    _vm._v(
                      "\n                               Lets discuss about whats happening around the world politics.\n                           "
                    )
                  ]),
                  _vm._v(" "),
                  _vm._m(23),
                  _vm._v(" "),
                  _c("div", { staticClass: "tt-innerwrapper" }, [
                    _c(
                      "a",
                      { staticClass: "tt-btn-icon", attrs: { href: "#" } },
                      [
                        _c("i", { staticClass: "tt-icon" }, [
                          _c("svg", [
                            _c("use", {
                              attrs: { "xlink:href": "#icon-favorite" }
                            })
                          ])
                        ])
                      ]
                    )
                  ])
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-md-6 col-lg-4" }, [
              _c("div", { staticClass: "tt-item" }, [
                _vm._m(24),
                _vm._v(" "),
                _c("div", { staticClass: "tt-item-layout" }, [
                  _c("div", { staticClass: "tt-innerwrapper" }, [
                    _vm._v(
                      "\n                               Lets discuss about whats happening around the world politics.\n                           "
                    )
                  ]),
                  _vm._v(" "),
                  _vm._m(25),
                  _vm._v(" "),
                  _c("div", { staticClass: "tt-innerwrapper" }, [
                    _c(
                      "a",
                      { staticClass: "tt-btn-icon", attrs: { href: "#" } },
                      [
                        _c("i", { staticClass: "tt-icon" }, [
                          _c("svg", [
                            _c("use", {
                              attrs: { "xlink:href": "#icon-favorite" }
                            })
                          ])
                        ])
                      ]
                    )
                  ])
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-md-6 col-lg-4" }, [
              _c("div", { staticClass: "tt-item" }, [
                _vm._m(26),
                _vm._v(" "),
                _c("div", { staticClass: "tt-item-layout" }, [
                  _c("div", { staticClass: "tt-innerwrapper" }, [
                    _vm._v(
                      "\n                               Lets discuss about whats happening around the world politics.\n                           "
                    )
                  ]),
                  _vm._v(" "),
                  _vm._m(27),
                  _vm._v(" "),
                  _c("div", { staticClass: "tt-innerwrapper" }, [
                    _c(
                      "a",
                      { staticClass: "tt-btn-icon", attrs: { href: "#" } },
                      [
                        _c("i", { staticClass: "tt-icon" }, [
                          _c("svg", [
                            _c("use", {
                              attrs: { "xlink:href": "#icon-favorite" }
                            })
                          ])
                        ])
                      ]
                    )
                  ])
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-md-6 col-lg-4" }, [
              _c("div", { staticClass: "tt-item" }, [
                _vm._m(28),
                _vm._v(" "),
                _c("div", { staticClass: "tt-item-layout" }, [
                  _c("div", { staticClass: "tt-innerwrapper" }, [
                    _vm._v(
                      "\n                               Lets discuss about whats happening around the world politics.\n                           "
                    )
                  ]),
                  _vm._v(" "),
                  _vm._m(29),
                  _vm._v(" "),
                  _c("div", { staticClass: "tt-innerwrapper" }, [
                    _c(
                      "a",
                      { staticClass: "tt-btn-icon", attrs: { href: "#" } },
                      [
                        _c("i", { staticClass: "tt-icon" }, [
                          _c("svg", [
                            _c("use", {
                              attrs: { "xlink:href": "#icon-favorite" }
                            })
                          ])
                        ])
                      ]
                    )
                  ])
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-12" }, [
              _c("div", { staticClass: "tt-row-btn" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn-icon js-topiclist-showmore",
                    attrs: { type: "button" }
                  },
                  [
                    _c("svg", { staticClass: "tt-icon" }, [
                      _c("use", {
                        attrs: { "xlink:href": "#icon-load_lore_icon" }
                      })
                    ])
                  ]
                )
              ])
            ])
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "tt-popup-settings", attrs: { id: "js-popup-settings" } },
      [
        _c("div", { staticClass: "tt-btn-col-close" }, [
          _c("a", { attrs: { href: "#" } }, [
            _c("span", { staticClass: "tt-icon-title" }, [
              _c("svg", [
                _c("use", { attrs: { "xlink:href": "#icon-settings_fill" } })
              ])
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "tt-icon-text" }, [
              _vm._v("\n\t\t\t\tSettings\n\t\t\t")
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "tt-icon-close" }, [
              _c("svg", [
                _c("use", { attrs: { "xlink:href": "#icon-cancel" } })
              ])
            ])
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tt-item-header" }, [
      _c("ul", { staticClass: "tt-list-badge" }, [
        _c("li", [
          _c("a", { attrs: { href: "#" } }, [
            _c("span", { staticClass: "tt-color01 tt-badge" }, [
              _vm._v("politics")
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("h6", { staticClass: "tt-title" }, [
        _c("a", { attrs: { href: "page-categories-single.html" } }, [
          _vm._v("Threads - 1,245")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "innerwrapper" }, [
      _c("h6", { staticClass: "tt-title" }, [_vm._v("Similar TAGS")]),
      _vm._v(" "),
      _c("ul", { staticClass: "tt-list-badge" }, [
        _c("li", [
          _c("a", { attrs: { href: "#" } }, [
            _c("span", { staticClass: "tt-badge" }, [_vm._v("world politics")])
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("a", { attrs: { href: "#" } }, [
            _c("span", { staticClass: "tt-badge" }, [_vm._v("human rights")])
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("a", { attrs: { href: "#" } }, [
            _c("span", { staticClass: "tt-badge" }, [_vm._v("trump")])
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("a", { attrs: { href: "#" } }, [
            _c("span", { staticClass: "tt-badge" }, [_vm._v("climate change")])
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("a", { attrs: { href: "#" } }, [
            _c("span", { staticClass: "tt-badge" }, [_vm._v("foreign policy")])
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tt-item-header" }, [
      _c("ul", { staticClass: "tt-list-badge" }, [
        _c("li", [
          _c("a", { attrs: { href: "#" } }, [
            _c("span", { staticClass: "tt-color02 tt-badge" }, [
              _vm._v("video")
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("h6", { staticClass: "tt-title" }, [
        _c("a", { attrs: { href: "page-categories-single.html" } }, [
          _vm._v("Threads - 368")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tt-innerwrapper" }, [
      _c("h6", { staticClass: "tt-title" }, [_vm._v("Similar TAGS")]),
      _vm._v(" "),
      _c("ul", { staticClass: "tt-list-badge" }, [
        _c("li", [
          _c("a", { attrs: { href: "#" } }, [
            _c("span", { staticClass: "tt-badge" }, [_vm._v("movies")])
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("a", { attrs: { href: "#" } }, [
            _c("span", { staticClass: "tt-badge" }, [_vm._v("new movies")])
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("a", { attrs: { href: "#" } }, [
            _c("span", { staticClass: "tt-badge" }, [_vm._v("marvel movies")])
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("a", { attrs: { href: "#" } }, [
            _c("span", { staticClass: "tt-badge" }, [_vm._v("climate change")])
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("a", { attrs: { href: "#" } }, [
            _c("span", { staticClass: "tt-badge" }, [_vm._v("netflix")])
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("a", { attrs: { href: "#" } }, [
            _c("span", { staticClass: "tt-badge" }, [_vm._v("prime")])
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tt-item-header" }, [
      _c("ul", { staticClass: "tt-list-badge" }, [
        _c("li", [
          _c("a", { attrs: { href: "#" } }, [
            _c("span", { staticClass: "tt-color03 tt-badge" }, [
              _vm._v("exchange")
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("h6", { staticClass: "tt-title" }, [
        _c("a", { attrs: { href: "page-categories-single.html" } }, [
          _vm._v("Threads - 381")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tt-innerwrapper" }, [
      _c("h6", { staticClass: "tt-title" }, [_vm._v("Similar TAGS")]),
      _vm._v(" "),
      _c("ul", { staticClass: "tt-list-badge" }, [
        _c("li", [
          _c("a", { attrs: { href: "#" } }, [
            _c("span", { staticClass: "tt-badge" }, [_vm._v("world politics")])
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("a", { attrs: { href: "#" } }, [
            _c("span", { staticClass: "tt-badge" }, [_vm._v("human rights")])
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("a", { attrs: { href: "#" } }, [
            _c("span", { staticClass: "tt-badge" }, [_vm._v("trump")])
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("a", { attrs: { href: "#" } }, [
            _c("span", { staticClass: "tt-badge" }, [_vm._v("climate change")])
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("a", { attrs: { href: "#" } }, [
            _c("span", { staticClass: "tt-badge" }, [_vm._v("foreign policy")])
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tt-item-header" }, [
      _c("ul", { staticClass: "tt-list-badge" }, [
        _c("li", [
          _c("a", { attrs: { href: "#" } }, [
            _c("span", { staticClass: "tt-color04 tt-badge" }, [_vm._v("pets")])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("h6", { staticClass: "tt-title" }, [
        _c("a", { attrs: { href: "page-categories-single.html" } }, [
          _vm._v("Threads - 98")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tt-innerwrapper" }, [
      _c("h6", { staticClass: "tt-title" }, [_vm._v("Similar TAGS")]),
      _vm._v(" "),
      _c("ul", { staticClass: "tt-list-badge" }, [
        _c("li", [
          _c("a", { attrs: { href: "#" } }, [
            _c("span", { staticClass: "tt-badge" }, [_vm._v("world politics")])
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("a", { attrs: { href: "#" } }, [
            _c("span", { staticClass: "tt-badge" }, [_vm._v("human rights")])
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("a", { attrs: { href: "#" } }, [
            _c("span", { staticClass: "tt-badge" }, [_vm._v("trump")])
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("a", { attrs: { href: "#" } }, [
            _c("span", { staticClass: "tt-badge" }, [_vm._v("climate change")])
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("a", { attrs: { href: "#" } }, [
            _c("span", { staticClass: "tt-badge" }, [_vm._v("foreign policy")])
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tt-item-header" }, [
      _c("ul", { staticClass: "tt-list-badge" }, [
        _c("li", [
          _c("a", { attrs: { href: "#" } }, [
            _c("span", { staticClass: "tt-color05 tt-badge" }, [
              _vm._v("music")
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("h6", { staticClass: "tt-title" }, [
        _c("a", { attrs: { href: "page-categories-single.html" } }, [
          _vm._v("Threads - 28")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tt-innerwrapper" }, [
      _c("h6", { staticClass: "tt-title" }, [_vm._v("Similar TAGS")]),
      _vm._v(" "),
      _c("ul", { staticClass: "tt-list-badge" }, [
        _c("li", [
          _c("a", { attrs: { href: "#" } }, [
            _c("span", { staticClass: "tt-badge" }, [_vm._v("world politics")])
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("a", { attrs: { href: "#" } }, [
            _c("span", { staticClass: "tt-badge" }, [_vm._v("human rights")])
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("a", { attrs: { href: "#" } }, [
            _c("span", { staticClass: "tt-badge" }, [_vm._v("trump")])
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("a", { attrs: { href: "#" } }, [
            _c("span", { staticClass: "tt-badge" }, [_vm._v("climate change")])
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("a", { attrs: { href: "#" } }, [
            _c("span", { staticClass: "tt-badge" }, [_vm._v("foreign policy")])
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tt-item-header" }, [
      _c("ul", { staticClass: "tt-list-badge" }, [
        _c("li", [
          _c("a", { attrs: { href: "#" } }, [
            _c("span", { staticClass: "tt-color06 tt-badge" }, [
              _vm._v("movies")
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("h6", { staticClass: "tt-title" }, [
        _c("a", { attrs: { href: "page-categories-single.html" } }, [
          _vm._v("Threads - 74")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tt-innerwrapper" }, [
      _c("h6", { staticClass: "tt-title" }, [_vm._v("Similar TAGS")]),
      _vm._v(" "),
      _c("ul", { staticClass: "tt-list-badge" }, [
        _c("li", [
          _c("a", { attrs: { href: "#" } }, [
            _c("span", { staticClass: "tt-badge" }, [_vm._v("world politics")])
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("a", { attrs: { href: "#" } }, [
            _c("span", { staticClass: "tt-badge" }, [_vm._v("human rights")])
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("a", { attrs: { href: "#" } }, [
            _c("span", { staticClass: "tt-badge" }, [_vm._v("trump")])
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("a", { attrs: { href: "#" } }, [
            _c("span", { staticClass: "tt-badge" }, [_vm._v("climate change")])
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("a", { attrs: { href: "#" } }, [
            _c("span", { staticClass: "tt-badge" }, [_vm._v("foreign policy")])
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tt-item-header" }, [
      _c("ul", { staticClass: "tt-list-badge" }, [
        _c("li", [
          _c("a", { attrs: { href: "#" } }, [
            _c("span", { staticClass: "tt-color15 tt-badge" }, [
              _vm._v("nature")
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("h6", { staticClass: "tt-title" }, [
        _c("a", { attrs: { href: "page-categories-single.html" } }, [
          _vm._v("Threads - 1,245")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tt-innerwrapper" }, [
      _c("h6", { staticClass: "tt-title" }, [_vm._v("Similar TAGS")]),
      _vm._v(" "),
      _c("ul", { staticClass: "tt-list-badge" }, [
        _c("li", [
          _c("a", { attrs: { href: "#" } }, [
            _c("span", { staticClass: "tt-badge" }, [_vm._v("world politics")])
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("a", { attrs: { href: "#" } }, [
            _c("span", { staticClass: "tt-badge" }, [_vm._v("human rights")])
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("a", { attrs: { href: "#" } }, [
            _c("span", { staticClass: "tt-badge" }, [_vm._v("trump")])
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("a", { attrs: { href: "#" } }, [
            _c("span", { staticClass: "tt-badge" }, [_vm._v("climate change")])
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("a", { attrs: { href: "#" } }, [
            _c("span", { staticClass: "tt-badge" }, [_vm._v("foreign policy")])
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tt-item-header" }, [
      _c("ul", { staticClass: "tt-list-badge" }, [
        _c("li", [
          _c("a", { attrs: { href: "#" } }, [
            _c("span", { staticClass: "tt-color07 tt-badge" }, [
              _vm._v("video games")
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("h6", { staticClass: "tt-title" }, [
        _c("a", { attrs: { href: "page-categories-single.html" } }, [
          _vm._v("Threads - 1,245")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tt-innerwrapper" }, [
      _c("h6", { staticClass: "tt-title" }, [_vm._v("Similar TAGS")]),
      _vm._v(" "),
      _c("ul", { staticClass: "tt-list-badge" }, [
        _c("li", [
          _c("a", { attrs: { href: "#" } }, [
            _c("span", { staticClass: "tt-badge" }, [_vm._v("world politics")])
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("a", { attrs: { href: "#" } }, [
            _c("span", { staticClass: "tt-badge" }, [_vm._v("human rights")])
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("a", { attrs: { href: "#" } }, [
            _c("span", { staticClass: "tt-badge" }, [_vm._v("trump")])
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("a", { attrs: { href: "#" } }, [
            _c("span", { staticClass: "tt-badge" }, [_vm._v("climate change")])
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("a", { attrs: { href: "#" } }, [
            _c("span", { staticClass: "tt-badge" }, [_vm._v("foreign policy")])
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tt-item-header" }, [
      _c("ul", { staticClass: "tt-list-badge" }, [
        _c("li", [
          _c("a", { attrs: { href: "#" } }, [
            _c("span", { staticClass: "tt-color08 tt-badge" }, [
              _vm._v("youtube")
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("h6", { staticClass: "tt-title" }, [
        _c("a", { attrs: { href: "page-categories-single.html" } }, [
          _vm._v("Threads - 1,245")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tt-innerwrapper" }, [
      _c("h6", { staticClass: "tt-title" }, [_vm._v("Similar TAGS")]),
      _vm._v(" "),
      _c("ul", { staticClass: "tt-list-badge" }, [
        _c("li", [
          _c("a", { attrs: { href: "#" } }, [
            _c("span", { staticClass: "tt-badge" }, [_vm._v("world politics")])
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("a", { attrs: { href: "#" } }, [
            _c("span", { staticClass: "tt-badge" }, [_vm._v("human rights")])
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("a", { attrs: { href: "#" } }, [
            _c("span", { staticClass: "tt-badge" }, [_vm._v("trump")])
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("a", { attrs: { href: "#" } }, [
            _c("span", { staticClass: "tt-badge" }, [_vm._v("climate change")])
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("a", { attrs: { href: "#" } }, [
            _c("span", { staticClass: "tt-badge" }, [_vm._v("foreign policy")])
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tt-item-header" }, [
      _c("ul", { staticClass: "tt-list-badge" }, [
        _c("li", [
          _c("a", { attrs: { href: "#" } }, [
            _c("span", { staticClass: "tt-color09 tt-badge" }, [
              _vm._v("social")
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("h6", { staticClass: "tt-title" }, [
        _c("a", { attrs: { href: "page-categories-single.html" } }, [
          _vm._v("Threads - 1,245")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tt-innerwrapper" }, [
      _c("h6", { staticClass: "tt-title" }, [_vm._v("Similar TAGS")]),
      _vm._v(" "),
      _c("ul", { staticClass: "tt-list-badge" }, [
        _c("li", [
          _c("a", { attrs: { href: "#" } }, [
            _c("span", { staticClass: "tt-badge" }, [_vm._v("world politics")])
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("a", { attrs: { href: "#" } }, [
            _c("span", { staticClass: "tt-badge" }, [_vm._v("human rights")])
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("a", { attrs: { href: "#" } }, [
            _c("span", { staticClass: "tt-badge" }, [_vm._v("trump")])
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("a", { attrs: { href: "#" } }, [
            _c("span", { staticClass: "tt-badge" }, [_vm._v("climate change")])
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("a", { attrs: { href: "#" } }, [
            _c("span", { staticClass: "tt-badge" }, [_vm._v("foreign policy")])
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tt-item-header" }, [
      _c("ul", { staticClass: "tt-list-badge" }, [
        _c("li", [
          _c("a", { attrs: { href: "#" } }, [
            _c("span", { staticClass: "tt-color10 tt-badge" }, [
              _vm._v("science")
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("h6", { staticClass: "tt-title" }, [
        _c("a", { attrs: { href: "page-categories-single.html" } }, [
          _vm._v("Threads - 1,245")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tt-innerwrapper" }, [
      _c("h6", { staticClass: "tt-title" }, [_vm._v("Similar TAGS")]),
      _vm._v(" "),
      _c("ul", { staticClass: "tt-list-badge" }, [
        _c("li", [
          _c("a", { attrs: { href: "#" } }, [
            _c("span", { staticClass: "tt-badge" }, [_vm._v("world politics")])
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("a", { attrs: { href: "#" } }, [
            _c("span", { staticClass: "tt-badge" }, [_vm._v("human rights")])
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("a", { attrs: { href: "#" } }, [
            _c("span", { staticClass: "tt-badge" }, [_vm._v("trump")])
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("a", { attrs: { href: "#" } }, [
            _c("span", { staticClass: "tt-badge" }, [_vm._v("climate change")])
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("a", { attrs: { href: "#" } }, [
            _c("span", { staticClass: "tt-badge" }, [_vm._v("foreign policy")])
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tt-item-header" }, [
      _c("ul", { staticClass: "tt-list-badge" }, [
        _c("li", [
          _c("a", { attrs: { href: "#" } }, [
            _c("span", { staticClass: "tt-color11 tt-badge" }, [
              _vm._v("entertainment")
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("h6", { staticClass: "tt-title" }, [
        _c("a", { attrs: { href: "page-categories-single.html" } }, [
          _vm._v("Threads - 1,245")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tt-innerwrapper" }, [
      _c("h6", { staticClass: "tt-title" }, [_vm._v("Similar TAGS")]),
      _vm._v(" "),
      _c("ul", { staticClass: "tt-list-badge" }, [
        _c("li", [
          _c("a", { attrs: { href: "#" } }, [
            _c("span", { staticClass: "tt-badge" }, [_vm._v("world politics")])
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("a", { attrs: { href: "#" } }, [
            _c("span", { staticClass: "tt-badge" }, [_vm._v("human rights")])
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("a", { attrs: { href: "#" } }, [
            _c("span", { staticClass: "tt-badge" }, [_vm._v("trump")])
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("a", { attrs: { href: "#" } }, [
            _c("span", { staticClass: "tt-badge" }, [_vm._v("climate change")])
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("a", { attrs: { href: "#" } }, [
            _c("span", { staticClass: "tt-badge" }, [_vm._v("foreign policy")])
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tt-item-header" }, [
      _c("ul", { staticClass: "tt-list-badge" }, [
        _c("li", [
          _c("a", { attrs: { href: "#" } }, [
            _c("span", { staticClass: "tt-color04 tt-badge" }, [_vm._v("pets")])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("h6", { staticClass: "tt-title" }, [
        _c("a", { attrs: { href: "page-categories-single.html" } }, [
          _vm._v("Threads - 1,245")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tt-innerwrapper" }, [
      _c("h6", { staticClass: "tt-title" }, [_vm._v("Similar TAGS")]),
      _vm._v(" "),
      _c("ul", { staticClass: "tt-list-badge" }, [
        _c("li", [
          _c("a", { attrs: { href: "#" } }, [
            _c("span", { staticClass: "tt-badge" }, [_vm._v("world politics")])
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("a", { attrs: { href: "#" } }, [
            _c("span", { staticClass: "tt-badge" }, [_vm._v("human rights")])
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("a", { attrs: { href: "#" } }, [
            _c("span", { staticClass: "tt-badge" }, [_vm._v("trump")])
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("a", { attrs: { href: "#" } }, [
            _c("span", { staticClass: "tt-badge" }, [_vm._v("climate change")])
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("a", { attrs: { href: "#" } }, [
            _c("span", { staticClass: "tt-badge" }, [_vm._v("foreign policy")])
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tt-item-header" }, [
      _c("ul", { staticClass: "tt-list-badge" }, [
        _c("li", [
          _c("a", { attrs: { href: "#" } }, [
            _c("span", { staticClass: "tt-color05 tt-badge" }, [
              _vm._v("music")
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("h6", { staticClass: "tt-title" }, [
        _c("a", { attrs: { href: "page-categories-single.html" } }, [
          _vm._v("Threads - 1,245")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tt-innerwrapper" }, [
      _c("h6", { staticClass: "tt-title" }, [_vm._v("Similar TAGS")]),
      _vm._v(" "),
      _c("ul", { staticClass: "tt-list-badge" }, [
        _c("li", [
          _c("a", { attrs: { href: "#" } }, [
            _c("span", { staticClass: "tt-badge" }, [_vm._v("world politics")])
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("a", { attrs: { href: "#" } }, [
            _c("span", { staticClass: "tt-badge" }, [_vm._v("human rights")])
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("a", { attrs: { href: "#" } }, [
            _c("span", { staticClass: "tt-badge" }, [_vm._v("trump")])
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("a", { attrs: { href: "#" } }, [
            _c("span", { staticClass: "tt-badge" }, [_vm._v("climate change")])
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("a", { attrs: { href: "#" } }, [
            _c("span", { staticClass: "tt-badge" }, [_vm._v("foreign policy")])
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tt-item-header" }, [
      _c("ul", { staticClass: "tt-list-badge" }, [
        _c("li", [
          _c("a", { attrs: { href: "#" } }, [
            _c("span", { staticClass: "tt-color06 tt-badge" }, [
              _vm._v("movies")
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("h6", { staticClass: "tt-title" }, [
        _c("a", { attrs: { href: "page-categories-single.html" } }, [
          _vm._v("Threads - 1,245")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tt-innerwrapper" }, [
      _c("h6", { staticClass: "tt-title" }, [_vm._v("Similar TAGS")]),
      _vm._v(" "),
      _c("ul", { staticClass: "tt-list-badge" }, [
        _c("li", [
          _c("a", { attrs: { href: "#" } }, [
            _c("span", { staticClass: "tt-badge" }, [_vm._v("world politics")])
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("a", { attrs: { href: "#" } }, [
            _c("span", { staticClass: "tt-badge" }, [_vm._v("human rights")])
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("a", { attrs: { href: "#" } }, [
            _c("span", { staticClass: "tt-badge" }, [_vm._v("trump")])
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("a", { attrs: { href: "#" } }, [
            _c("span", { staticClass: "tt-badge" }, [_vm._v("climate change")])
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("a", { attrs: { href: "#" } }, [
            _c("span", { staticClass: "tt-badge" }, [_vm._v("foreign policy")])
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }