var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("nav", { staticClass: "panel-menu", attrs: { id: "mobile-menu" } }, [
      _c("ul"),
      _vm._v(" "),
      _c("div", { staticClass: "mm-navbtn-names" }, [
        _c("div", { staticClass: "mm-closebtn" }, [
          _vm._v("\n            Close\n            "),
          _c("div", { staticClass: "tt-icon" }, [
            _c("svg", [_c("use", { attrs: { "xlink:href": "#icon-cancel" } })])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "mm-backbtn" }, [_vm._v("Back")])
      ])
    ]),
    _vm._v(" "),
    _c("header", { attrs: { id: "tt-header" } }, [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "row tt-row no-gutters" }, [
          _c("div", { staticClass: "col-auto" }, [
            _c(
              "a",
              { staticClass: "toggle-mobile-menu", attrs: { href: "#" } },
              [
                _c("svg", { staticClass: "tt-icon" }, [
                  _c("use", { attrs: { "xlink:href": "#icon-menu_icon" } })
                ])
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "tt-logo" },
              [
                _c("router-link", { attrs: { to: "/" } }, [
                  _c("img", { attrs: { src: _vm.getMainLogo, alt: "" } })
                ])
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "tt-desktop-menu" }, [
              _c("nav", [
                _c("ul", [
                  _c(
                    "li",
                    [
                      _c("router-link", { attrs: { to: "/categories" } }, [
                        _vm._v("Categories")
                      ])
                    ],
                    1
                  )
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "tt-search" }, [
              _c(
                "button",
                {
                  staticClass: "tt-search-toggle",
                  attrs: {
                    "data-toggle": "modal",
                    "data-target": "#modalAdvancedSearch"
                  }
                },
                [
                  _c("svg", { staticClass: "tt-icon" }, [
                    _c("use", { attrs: { "xlink:href": "#icon-search" } })
                  ])
                ]
              ),
              _vm._v(" "),
              _c("form", { staticClass: "search-wrapper" }, [
                _c("div", { staticClass: "search-form" }, [
                  _c("input", {
                    staticClass: "tt-search__input",
                    attrs: { type: "text", placeholder: "Search" }
                  }),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "tt-search__btn",
                      attrs: { type: "submit" }
                    },
                    [
                      _c("svg", { staticClass: "tt-icon" }, [
                        _c("use", { attrs: { "xlink:href": "#icon-search" } })
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c("button", { staticClass: "tt-search__close" }, [
                    _c("svg", { staticClass: "tt-icon" }, [
                      _c("use", { attrs: { "xlink:href": "#cancel" } })
                    ])
                  ])
                ]),
                _vm._v(" "),
                _vm._m(0)
              ])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-auto ml-auto" }, [
            _c(
              "div",
              { staticClass: "tt-user-info d-flex justify-content-center" },
              [
                _c("a", { staticClass: "tt-btn-icon", attrs: { href: "#" } }, [
                  _c("i", { staticClass: "tt-icon" }, [
                    _c("svg", [
                      _c("use", {
                        attrs: { "xlink:href": "#icon-notification" }
                      })
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "tt-avatar-icon tt-size-md" }, [
                  _c("i", { staticClass: "tt-icon" }, [
                    _c("svg", [
                      _c("use", { attrs: { "xlink:href": "#icon-ava-a" } })
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", {}, [
                  _c("h4", [_vm._v(_vm._s(_vm.getAuthUser("full_name")))])
                ])
              ]
            )
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _c(
      "a",
      {
        staticClass: "tt-btn-create-topic",
        attrs: { href: "page-create-topic.html" }
      },
      [
        _c("span", { staticClass: "tt-icon" }, [
          _c("svg", [
            _c("use", { attrs: { "xlink:href": "#icon-create_new" } })
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "search-results" }, [
      _c("div", { staticClass: "tt-search-scroll" }, [
        _c("ul", [
          _c("li", [
            _c("a", { attrs: { href: "page-single-topic.html" } }, [
              _c("h6", { staticClass: "tt-title" }, [
                _vm._v("Rdr2 secret easter eggs")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "tt-description" }, [
                _vm._v(
                  "\n                                            Here’s what I’ve found in Red Dead Redem..\n                                            "
                )
              ])
            ])
          ]),
          _vm._v(" "),
          _c("li", [
            _c("a", { attrs: { href: "page-single-topic.html" } }, [
              _c("h6", { staticClass: "tt-title" }, [
                _vm._v("Top 10 easter eggs in Red Dead Rede..")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "tt-description" }, [
                _vm._v(
                  "\n                                                You can find these easter eggs in Red Dea..\n                                            "
                )
              ])
            ])
          ]),
          _vm._v(" "),
          _c("li", [
            _c("a", { attrs: { href: "page-single-topic.html" } }, [
              _c("h6", { staticClass: "tt-title" }, [
                _vm._v("Red Dead Redemtion: Arthur Morgan..")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "tt-description" }, [
                _vm._v(
                  "\n                                                Here’s what I’ve found in Red Dead Redem..\n                                            "
                )
              ])
            ])
          ]),
          _vm._v(" "),
          _c("li", [
            _c("a", { attrs: { href: "page-single-topic.html" } }, [
              _c("h6", { staticClass: "tt-title" }, [
                _vm._v("Rdr2 secret easter eggs")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "tt-description" }, [
                _vm._v(
                  "\n                                            Here’s what I’ve found in Red Dead Redem..\n                                            "
                )
              ])
            ])
          ]),
          _vm._v(" "),
          _c("li", [
            _c("a", { attrs: { href: "page-single-topic.html" } }, [
              _c("h6", { staticClass: "tt-title" }, [
                _vm._v("Top 10 easter eggs in Red Dead Rede..")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "tt-description" }, [
                _vm._v(
                  "\n                                                You can find these easter eggs in Red Dea..\n                                            "
                )
              ])
            ])
          ]),
          _vm._v(" "),
          _c("li", [
            _c("a", { attrs: { href: "page-single-topic.html" } }, [
              _c("h6", { staticClass: "tt-title" }, [
                _vm._v("Red Dead Redemtion: Arthur Morgan..")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "tt-description" }, [
                _vm._v(
                  "\n                                                Here’s what I’ve found in Red Dead Redem..\n                                            "
                )
              ])
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "tt-view-all",
          attrs: {
            type: "button",
            "data-toggle": "modal",
            "data-target": "#modalAdvancedSearch"
          }
        },
        [_vm._v("Advanced Search")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }