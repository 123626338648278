var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "main",
      { staticClass: "tt-offset-small", attrs: { id: "tt-pageContent" } },
      [
        _c("div", { staticClass: "container" }, [
          _c("div", { staticClass: "tt-topic-list" }, [
            _vm._m(0),
            _vm._v(" "),
            _vm._m(1),
            _vm._v(" "),
            _c("div", { staticClass: "tt-item tt-itemselect" }, [
              _c("div", { staticClass: "tt-col-avatar" }, [
                _c("svg", { staticClass: "tt-icon" }, [
                  _c("use", { attrs: { "xlink:href": "#icon-ava-k" } })
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "tt-col-description" }, [
                _c("h6", { staticClass: "tt-title" }, [
                  _c("a", { attrs: { href: "page-single-topic.html" } }, [
                    _c("svg", { staticClass: "tt-icon" }, [
                      _c("use", { attrs: { "xlink:href": "#icon-pinned" } })
                    ]),
                    _vm._v(
                      "\n                        Halloween Costume Contest 2018\n                    "
                    )
                  ])
                ]),
                _vm._v(" "),
                _vm._m(2)
              ]),
              _vm._v(" "),
              _vm._m(3),
              _vm._v(" "),
              _c("div", { staticClass: "tt-col-value hide-mobile" }, [
                _vm._v("985")
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "tt-col-value tt-color-select hide-mobile" },
                [_vm._v("502")]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "tt-col-value hide-mobile" }, [
                _vm._v("15.1k")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "tt-col-value hide-mobile" }, [
                _vm._v("1h")
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "tt-item tt-itemselect" }, [
              _c("div", { staticClass: "tt-col-avatar" }, [
                _c("svg", { staticClass: "tt-icon" }, [
                  _c("use", { attrs: { "xlink:href": "#icon-ava-l" } })
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "tt-col-description" }, [
                _c("h6", { staticClass: "tt-title" }, [
                  _c("a", { attrs: { href: "page-single-topic.html" } }, [
                    _c("svg", { staticClass: "tt-icon" }, [
                      _c("use", { attrs: { "xlink:href": "#icon-locked" } })
                    ]),
                    _vm._v(
                      "\n                        We’re removing Envato Credits from Market\n                    "
                    )
                  ])
                ]),
                _vm._v(" "),
                _vm._m(4)
              ]),
              _vm._v(" "),
              _vm._m(5),
              _vm._v(" "),
              _c("div", { staticClass: "tt-col-value hide-mobile" }, [
                _vm._v("584")
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "tt-col-value tt-color-select  hide-mobile" },
                [_vm._v("35")]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "tt-col-value hide-mobile" }, [
                _vm._v("1.3k")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "tt-col-value hide-mobile" }, [
                _vm._v("2h")
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "tt-item tt-itemselect" }, [
              _c("div", { staticClass: "tt-col-avatar" }, [
                _c("svg", { staticClass: "tt-icon" }, [
                  _c("use", { attrs: { "xlink:href": "#icon-ava-d" } })
                ])
              ]),
              _vm._v(" "),
              _vm._m(6),
              _vm._v(" "),
              _vm._m(7),
              _vm._v(" "),
              _c("div", { staticClass: "tt-col-value  hide-mobile" }, [
                _vm._v("401")
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "tt-col-value tt-color-select  hide-mobile" },
                [_vm._v("975")]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "tt-col-value  hide-mobile" }, [
                _vm._v("12.6k")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "tt-col-value hide-mobile" }, [
                _vm._v("2h")
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "tt-item" }, [
              _c("div", { staticClass: "tt-col-avatar" }, [
                _c("svg", { staticClass: "tt-icon" }, [
                  _c("use", { attrs: { "xlink:href": "#icon-ava-c" } })
                ])
              ]),
              _vm._v(" "),
              _vm._m(8),
              _vm._v(" "),
              _vm._m(9),
              _vm._v(" "),
              _c("div", { staticClass: "tt-col-value  hide-mobile" }, [
                _vm._v("308")
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "tt-col-value tt-color-select  hide-mobile" },
                [_vm._v("660")]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "tt-col-value  hide-mobile" }, [
                _vm._v("8.3k")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "tt-col-value hide-mobile" }, [
                _vm._v("1d")
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "tt-item" }, [
              _c("div", { staticClass: "tt-col-avatar" }, [
                _c("svg", { staticClass: "tt-icon" }, [
                  _c("use", { attrs: { "xlink:href": "#icon-ava-n" } })
                ])
              ]),
              _vm._v(" "),
              _vm._m(10),
              _vm._v(" "),
              _vm._m(11),
              _vm._v(" "),
              _c("div", { staticClass: "tt-col-value hide-mobile" }, [
                _vm._v("358")
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "tt-col-value tt-color-select hide-mobile" },
                [_vm._v("68")]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "tt-col-value hide-mobile" }, [
                _vm._v("8.3k")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "tt-col-value hide-mobile" }, [
                _vm._v("1d")
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "tt-item" }, [
              _c("div", { staticClass: "tt-col-avatar" }, [
                _c("svg", { staticClass: "tt-icon" }, [
                  _c("use", { attrs: { "xlink:href": "#icon-ava-h" } })
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "tt-col-description" }, [
                _c("h6", { staticClass: "tt-title" }, [
                  _c("a", { attrs: { href: "page-single-topic.html" } }, [
                    _c("svg", { staticClass: "tt-icon" }, [
                      _c("use", { attrs: { "xlink:href": "#icon-locked" } })
                    ]),
                    _vm._v(
                      "\n                        We Want to Hear From You! What Would You Like?\n                    "
                    )
                  ])
                ]),
                _vm._v(" "),
                _vm._m(12)
              ]),
              _vm._v(" "),
              _vm._m(13),
              _vm._v(" "),
              _c("div", { staticClass: "tt-col-value hide-mobile" }, [
                _vm._v("671")
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "tt-col-value tt-color-select  hide-mobile" },
                [_vm._v("29")]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "tt-col-value hide-mobile" }, [
                _vm._v("1.3k")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "tt-col-value hide-mobile" }, [
                _vm._v("2d")
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "tt-item" }, [
              _c("div", { staticClass: "tt-col-avatar" }, [
                _c("svg", { staticClass: "tt-icon" }, [
                  _c("use", { attrs: { "xlink:href": "#icon-ava-t" } })
                ])
              ]),
              _vm._v(" "),
              _vm._m(14),
              _vm._v(" "),
              _vm._m(15),
              _vm._v(" "),
              _c("div", { staticClass: "tt-col-value hide-mobile" }, [
                _vm._v("364")
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "tt-col-value tt-color-select  hide-mobile" },
                [_vm._v("36")]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "tt-col-value  hide-mobile" }, [
                _vm._v("982")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "tt-col-value hide-mobile" }, [
                _vm._v("2d")
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "tt-item" }, [
              _c("div", { staticClass: "tt-col-avatar" }, [
                _c("svg", { staticClass: "tt-icon" }, [
                  _c("use", { attrs: { "xlink:href": "#icon-ava-k" } })
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "tt-col-description" }, [
                _c("h6", { staticClass: "tt-title" }, [
                  _c("a", { attrs: { href: "page-single-topic.html" } }, [
                    _c("svg", { staticClass: "tt-icon" }, [
                      _c("use", { attrs: { "xlink:href": "#icon-verified" } })
                    ]),
                    _vm._v(
                      "\n                        Microsoft Word and Power Point\n                    "
                    )
                  ])
                ]),
                _vm._v(" "),
                _vm._m(16)
              ]),
              _vm._v(" "),
              _vm._m(17),
              _vm._v(" "),
              _c("div", { staticClass: "tt-col-value  hide-mobile" }, [
                _vm._v("698")
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "tt-col-value tt-color-select  hide-mobile" },
                [_vm._v("78")]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "tt-col-value  hide-mobile" }, [
                _vm._v("2.1k")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "tt-col-value hide-mobile" }, [
                _vm._v("3d")
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "tt-item" }, [
              _c("div", { staticClass: "tt-col-avatar" }, [
                _c("svg", { staticClass: "tt-icon" }, [
                  _c("use", { attrs: { "xlink:href": "#icon-ava-v" } })
                ])
              ]),
              _vm._v(" "),
              _vm._m(18),
              _vm._v(" "),
              _vm._m(19),
              _vm._v(" "),
              _c("div", { staticClass: "tt-col-value  hide-mobile" }, [
                _vm._v("12")
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "tt-col-value tt-color-select  hide-mobile" },
                [_vm._v("3")]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "tt-col-value  hide-mobile" }, [
                _vm._v("268")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "tt-col-value hide-mobile" }, [
                _vm._v("3d")
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "tt-item" }, [
              _c("div", { staticClass: "tt-col-avatar" }, [
                _c("svg", { staticClass: "tt-icon" }, [
                  _c("use", { attrs: { "xlink:href": "#icon-ava-k" } })
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "tt-col-description" }, [
                _c("h6", { staticClass: "tt-title" }, [
                  _c("a", { attrs: { href: "page-single-topic.html" } }, [
                    _c("svg", { staticClass: "tt-icon" }, [
                      _c("use", { attrs: { "xlink:href": "#icon-pinned" } })
                    ]),
                    _vm._v(
                      "\n                       Proform or looking for contacting billing department\n                    "
                    )
                  ])
                ]),
                _vm._v(" "),
                _vm._m(20)
              ]),
              _vm._v(" "),
              _vm._m(21),
              _vm._v(" "),
              _c("div", { staticClass: "tt-col-value hide-mobile" }, [
                _vm._v("274")
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "tt-col-value tt-color-select hide-mobile" },
                [_vm._v("114")]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "tt-col-value  hide-mobile" }, [
                _vm._v("2.3k")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "tt-col-value hide-mobile" }, [
                _vm._v("3d")
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "tt-item" }, [
              _c("div", { staticClass: "tt-col-avatar" }, [
                _c("svg", { staticClass: "tt-icon" }, [
                  _c("use", { attrs: { "xlink:href": "#icon-ava-y" } })
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "tt-col-description" }, [
                _c("h6", { staticClass: "tt-title" }, [
                  _c("a", { attrs: { href: "page-single-topic.html" } }, [
                    _c("svg", { staticClass: "tt-icon" }, [
                      _c("use", { attrs: { "xlink:href": "#icon-locked" } })
                    ]),
                    _vm._v(
                      "\n                        Refund for wrongly purchase HTML template\n                    "
                    )
                  ])
                ]),
                _vm._v(" "),
                _vm._m(22)
              ]),
              _vm._v(" "),
              _vm._m(23),
              _vm._v(" "),
              _c("div", { staticClass: "tt-col-value hide-mobile" }, [
                _vm._v("657")
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "tt-col-value tt-color-select hide-mobile" },
                [_vm._v("177")]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "tt-col-value hide-mobile" }, [
                _vm._v("2.6k")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "tt-col-value hide-mobile" }, [
                _vm._v("3d")
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "tt-item" }, [
              _c("div", { staticClass: "tt-col-avatar" }, [
                _c("svg", { staticClass: "tt-icon" }, [
                  _c("use", { attrs: { "xlink:href": "#icon-ava-s" } })
                ])
              ]),
              _vm._v(" "),
              _vm._m(24),
              _vm._v(" "),
              _vm._m(25),
              _vm._v(" "),
              _c("div", { staticClass: "tt-col-value hide-mobile" }, [
                _vm._v("37")
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "tt-col-value tt-color-select hide-mobile" },
                [_vm._v("31")]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "tt-col-value hide-mobile" }, [
                _vm._v("257")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "tt-col-value hide-mobile" }, [
                _vm._v("4d")
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "tt-item" }, [
              _c("div", { staticClass: "tt-col-avatar" }, [
                _c("svg", { staticClass: "tt-icon" }, [
                  _c("use", { attrs: { "xlink:href": "#icon-ava-l" } })
                ])
              ]),
              _vm._v(" "),
              _vm._m(26),
              _vm._v(" "),
              _vm._m(27),
              _vm._v(" "),
              _c("div", { staticClass: "tt-col-value hide-mobile" }, [
                _vm._v("987")
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "tt-col-value tt-color-select hide-mobile" },
                [_vm._v("271")]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "tt-col-value hide-mobile" }, [
                _vm._v("3.8k")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "tt-col-value hide-mobile" }, [
                _vm._v("4d")
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "tt-item" }, [
              _c("div", { staticClass: "tt-col-avatar" }, [
                _c("svg", { staticClass: "tt-icon" }, [
                  _c("use", { attrs: { "xlink:href": "#icon-ava-n" } })
                ])
              ]),
              _vm._v(" "),
              _vm._m(28),
              _vm._v(" "),
              _vm._m(29),
              _vm._v(" "),
              _c("div", { staticClass: "tt-col-value hide-mobile" }, [
                _vm._v("324")
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "tt-col-value tt-color-select hide-mobile" },
                [_vm._v("163")]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "tt-col-value hide-mobile" }, [
                _vm._v("2.3k")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "tt-col-value hide-mobile" }, [
                _vm._v("5d")
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "tt-item" }, [
              _c("div", { staticClass: "tt-col-avatar" }, [
                _c("svg", { staticClass: "tt-icon" }, [
                  _c("use", { attrs: { "xlink:href": "#icon-ava-r" } })
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "tt-col-description" }, [
                _c("h6", { staticClass: "tt-title" }, [
                  _c("a", { attrs: { href: "page-single-topic.html" } }, [
                    _c("svg", { staticClass: "tt-icon" }, [
                      _c("use", { attrs: { "xlink:href": "#icon-locked" } })
                    ]),
                    _vm._v(
                      "\n                        Can’t change image on main page of Coaching Theme\n                    "
                    )
                  ])
                ]),
                _vm._v(" "),
                _vm._m(30)
              ]),
              _vm._v(" "),
              _vm._m(31),
              _vm._v(" "),
              _c("div", { staticClass: "tt-col-value hide-mobile" }, [
                _vm._v("879")
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "tt-col-value tt-color-select hide-mobile" },
                [_vm._v("237")]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "tt-col-value hide-mobile" }, [
                _vm._v("4.5k")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "tt-col-value hide-mobile" }, [
                _vm._v("5d")
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "tt-item" }, [
              _c("div", { staticClass: "tt-col-avatar" }, [
                _c("svg", { staticClass: "tt-icon" }, [
                  _c("use", { attrs: { "xlink:href": "#icon-ava-b" } })
                ])
              ]),
              _vm._v(" "),
              _vm._m(32),
              _vm._v(" "),
              _vm._m(33),
              _vm._v(" "),
              _c("div", { staticClass: "tt-col-value hide-mobile" }, [
                _vm._v("726")
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "tt-col-value tt-color-select hide-mobile" },
                [_vm._v("246")]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "tt-col-value hide-mobile" }, [
                _vm._v("7.6k")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "tt-col-value hide-mobile" }, [
                _vm._v("5d")
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "tt-item" }, [
              _c("div", { staticClass: "tt-col-avatar" }, [
                _c("svg", { staticClass: "tt-icon" }, [
                  _c("use", { attrs: { "xlink:href": "#icon-ava-a" } })
                ])
              ]),
              _vm._v(" "),
              _vm._m(34),
              _vm._v(" "),
              _vm._m(35),
              _vm._v(" "),
              _c("div", { staticClass: "tt-col-value hide-mobile" }, [
                _vm._v("674")
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "tt-col-value tt-color-select hide-mobile" },
                [_vm._v("128")]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "tt-col-value hide-mobile" }, [
                _vm._v("1.3k")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "tt-col-value hide-mobile" }, [
                _vm._v("5d")
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "tt-row-btn" }, [
              _c(
                "button",
                {
                  staticClass: "btn-icon js-topiclist-showmore",
                  attrs: { type: "button" }
                },
                [
                  _c("svg", { staticClass: "tt-icon" }, [
                    _c("use", {
                      attrs: { "xlink:href": "#icon-load_lore_icon" }
                    })
                  ])
                ]
              )
            ])
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tt-list-header" }, [
      _c("div", { staticClass: "tt-col-topic" }, [_vm._v("Topic")]),
      _vm._v(" "),
      _c("div", { staticClass: "tt-col-category" }, [_vm._v("Category")]),
      _vm._v(" "),
      _c("div", { staticClass: "tt-col-value hide-mobile" }, [_vm._v("Likes")]),
      _vm._v(" "),
      _c("div", { staticClass: "tt-col-value hide-mobile" }, [
        _vm._v("Replies")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "tt-col-value hide-mobile" }, [_vm._v("Views")]),
      _vm._v(" "),
      _c("div", { staticClass: "tt-col-value" }, [_vm._v("Activity")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "tt-topic-alert tt-alert-default",
        attrs: { role: "alert" }
      },
      [
        _c("a", { attrs: { href: "#", target: "_blank" } }, [
          _vm._v("4 new posts")
        ]),
        _vm._v(" are added recently, click here to load them.\n            ")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row align-items-center no-gutters" }, [
      _c("div", { staticClass: "col-11" }, [
        _c("ul", { staticClass: "tt-list-badge" }, [
          _c("li", { staticClass: "show-mobile" }, [
            _c("a", { attrs: { href: "#" } }, [
              _c("span", { staticClass: "tt-color01 tt-badge" }, [
                _vm._v("politics")
              ])
            ])
          ]),
          _vm._v(" "),
          _c("li", [
            _c("a", { attrs: { href: "#" } }, [
              _c("span", { staticClass: "tt-badge" }, [_vm._v("contests")])
            ])
          ]),
          _vm._v(" "),
          _c("li", [
            _c("a", { attrs: { href: "#" } }, [
              _c("span", { staticClass: "tt-badge" }, [_vm._v("authors")])
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-1 ml-auto show-mobile" }, [
        _c("div", { staticClass: "tt-value" }, [_vm._v("1h")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tt-col-category" }, [
      _c("span", { staticClass: "tt-color01 tt-badge" }, [_vm._v("politics")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "row align-items-center no-gutters  hide-desktope" },
      [
        _c("div", { staticClass: "col-11" }, [
          _c("ul", { staticClass: "tt-list-badge" }, [
            _c("li", { staticClass: "show-mobile" }, [
              _c("a", { attrs: { href: "#" } }, [
                _c("span", { staticClass: "tt-color02 tt-badge" }, [
                  _vm._v("video")
                ])
              ])
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-1 ml-auto show-mobile" }, [
          _c("div", { staticClass: "tt-value" }, [_vm._v("2h")])
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tt-col-category" }, [
      _c("span", { staticClass: "tt-color02 tt-badge" }, [_vm._v("video")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tt-col-description" }, [
      _c("h6", { staticClass: "tt-title" }, [
        _c("a", { attrs: { href: "page-single-topic.html" } }, [
          _vm._v(
            "\n                        Web Hosting Packages for ThemeForest WordPress\n                    "
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row align-items-center no-gutters" }, [
        _c("div", { staticClass: "col-11" }, [
          _c("ul", { staticClass: "tt-list-badge" }, [
            _c("li", { staticClass: "show-mobile" }, [
              _c("a", { attrs: { href: "#" } }, [
                _c("span", { staticClass: "tt-color03 tt-badge" }, [
                  _vm._v("exchange")
                ])
              ])
            ]),
            _vm._v(" "),
            _c("li", [
              _c("a", { attrs: { href: "#" } }, [
                _c("span", { staticClass: "tt-badge" }, [_vm._v("themeforest")])
              ])
            ]),
            _vm._v(" "),
            _c("li", [
              _c("a", { attrs: { href: "#" } }, [
                _c("span", { staticClass: "tt-badge" }, [_vm._v("elements")])
              ])
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-1 ml-auto show-mobile" }, [
          _c("div", { staticClass: "tt-value" }, [_vm._v("2h")])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tt-col-category" }, [
      _c("span", { staticClass: "tt-color03 tt-badge" }, [_vm._v("exchange")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tt-col-description" }, [
      _c("h6", { staticClass: "tt-title" }, [
        _c("a", { attrs: { href: "page-single-topic.html" } }, [
          _vm._v(
            "\n                        Review Queue Changes for VideoHive & PhotoDune\n                    "
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row align-items-center no-gutters" }, [
        _c("div", { staticClass: "col-11" }, [
          _c("ul", { staticClass: "tt-list-badge" }, [
            _c("li", { staticClass: "show-mobile" }, [
              _c("a", { attrs: { href: "#" } }, [
                _c("span", { staticClass: "tt-color04 tt-badge" }, [
                  _vm._v("pets")
                ])
              ])
            ]),
            _vm._v(" "),
            _c("li", [
              _c("a", { attrs: { href: "#" } }, [
                _c("span", { staticClass: "tt-badge" }, [_vm._v("videohive")])
              ])
            ]),
            _vm._v(" "),
            _c("li", [
              _c("a", { attrs: { href: "#" } }, [
                _c("span", { staticClass: "tt-badge" }, [_vm._v("photodune")])
              ])
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-1 ml-auto show-mobile" }, [
          _c("div", { staticClass: "tt-value" }, [_vm._v("1d")])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tt-col-category" }, [
      _c("span", { staticClass: "tt-color04 tt-badge" }, [_vm._v("pets")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tt-col-description" }, [
      _c("h6", { staticClass: "tt-title" }, [
        _c("a", { attrs: { href: "page-single-topic.html" } }, [
          _vm._v(
            "\n                        Does Envato act against the authors of Envato markets?\n                    "
          )
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "row align-items-center no-gutters  hide-desktope" },
        [
          _c("div", { staticClass: "col-11" }, [
            _c("ul", { staticClass: "tt-list-badge" }, [
              _c("li", { staticClass: "show-mobile" }, [
                _c("a", { attrs: { href: "#" } }, [
                  _c("span", { staticClass: "tt-color05 tt-badge" }, [
                    _vm._v("music")
                  ])
                ])
              ])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-1 ml-auto show-mobile" }, [
            _c("div", { staticClass: "tt-value" }, [_vm._v("1d")])
          ])
        ]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tt-col-category" }, [
      _c("span", { staticClass: "tt-color05 tt-badge" }, [_vm._v("music")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "row align-items-center no-gutters  hide-desktope" },
      [
        _c("div", { staticClass: "col-11" }, [
          _c("ul", { staticClass: "tt-list-badge" }, [
            _c("li", { staticClass: "show-mobile" }, [
              _c("a", { attrs: { href: "#" } }, [
                _c("span", { staticClass: "tt-color06 tt-badge" }, [
                  _vm._v("movies")
                ])
              ])
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-1 ml-auto show-mobile" }, [
          _c("div", { staticClass: "tt-value" }, [_vm._v("2d")])
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tt-col-category" }, [
      _c("span", { staticClass: "tt-color06 tt-badge" }, [_vm._v("movies")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tt-col-description" }, [
      _c("h6", { staticClass: "tt-title" }, [
        _c("a", { attrs: { href: "page-single-topic.html" } }, [
          _vm._v(
            "\n                        Cannot customize my intro\n                    "
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row align-items-center no-gutters" }, [
        _c("div", { staticClass: "col-11" }, [
          _c("ul", { staticClass: "tt-list-badge" }, [
            _c("li", { staticClass: "show-mobile" }, [
              _c("a", { attrs: { href: "#" } }, [
                _c("span", { staticClass: "tt-color07 tt-badge" }, [
                  _vm._v("video games")
                ])
              ])
            ]),
            _vm._v(" "),
            _c("li", [
              _c("a", { attrs: { href: "#" } }, [
                _c("span", { staticClass: "tt-badge" }, [_vm._v("videohive")])
              ])
            ]),
            _vm._v(" "),
            _c("li", [
              _c("a", { attrs: { href: "#" } }, [
                _c("span", { staticClass: "tt-badge" }, [_vm._v("photodune")])
              ])
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-1 ml-auto show-mobile" }, [
          _c("div", { staticClass: "tt-value" }, [_vm._v("2d")])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tt-col-category" }, [
      _c("span", { staticClass: "tt-color07 tt-badge" }, [
        _vm._v("video games")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "row align-items-center no-gutters hide-desktope" },
      [
        _c("div", { staticClass: "col-11" }, [
          _c("ul", { staticClass: "tt-list-badge " }, [
            _c("li", { staticClass: "show-mobile" }, [
              _c("a", { attrs: { href: "#" } }, [
                _c("span", { staticClass: "tt-color08 tt-badge" }, [
                  _vm._v("youtube")
                ])
              ])
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-1 ml-auto show-mobile" }, [
          _c("div", { staticClass: "tt-value" }, [_vm._v("3d")])
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tt-col-category" }, [
      _c("span", { staticClass: "tt-color08 tt-badge" }, [_vm._v("youtube")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tt-col-description" }, [
      _c("h6", { staticClass: "tt-title" }, [
        _c("a", { attrs: { href: "page-single-topic.html" } }, [
          _vm._v(
            "\n                        First website template got rejected.\n                    "
          )
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "row align-items-center no-gutters  hide-desktope" },
        [
          _c("div", { staticClass: "col-11" }, [
            _c("ul", { staticClass: "tt-list-badge" }, [
              _c("li", { staticClass: "show-mobile" }, [
                _c("a", { attrs: { href: "#" } }, [
                  _c("span", { staticClass: "tt-color09 tt-badge" }, [
                    _vm._v("social")
                  ])
                ])
              ])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-1 ml-auto show-mobile" }, [
            _c("div", { staticClass: "tt-value" }, [_vm._v("3d")])
          ])
        ]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tt-col-category" }, [
      _c("span", { staticClass: "tt-color09 tt-badge" }, [_vm._v("social")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row align-items-center no-gutters" }, [
      _c("div", { staticClass: "col-11" }, [
        _c("ul", { staticClass: "tt-list-badge" }, [
          _c("li", { staticClass: "show-mobile" }, [
            _c("a", { attrs: { href: "#" } }, [
              _c("span", { staticClass: "tt-color10 tt-badge" }, [
                _vm._v("science")
              ])
            ])
          ]),
          _vm._v(" "),
          _c("li", [
            _c("a", { attrs: { href: "#" } }, [
              _c("span", { staticClass: "tt-badge" }, [_vm._v("contests")])
            ])
          ]),
          _vm._v(" "),
          _c("li", [
            _c("a", { attrs: { href: "#" } }, [
              _c("span", { staticClass: "tt-badge" }, [_vm._v("authors")])
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-1 ml-auto show-mobile" }, [
        _c("div", { staticClass: "tt-value" }, [_vm._v("3d")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tt-col-category" }, [
      _c("span", { staticClass: "tt-color10 tt-badge" }, [_vm._v("science")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "row align-items-center no-gutters hide-desktope" },
      [
        _c("div", { staticClass: "col-11" }, [
          _c("ul", { staticClass: "tt-list-badge" }, [
            _c("li", { staticClass: "show-mobile" }, [
              _c("a", { attrs: { href: "#" } }, [
                _c("span", { staticClass: "tt-color11 tt-badge" }, [
                  _vm._v("entertainment")
                ])
              ])
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-1 ml-auto show-mobile" }, [
          _c("div", { staticClass: "tt-value" }, [_vm._v("3d")])
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tt-col-category" }, [
      _c("span", { staticClass: "tt-color11 tt-badge" }, [
        _vm._v("entertainment")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tt-col-description" }, [
      _c("h6", { staticClass: "tt-title" }, [
        _c("a", { attrs: { href: "page-single-topic.html" } }, [
          _vm._v(
            "\n                       Why all my affiliate balance is pending?\n                    "
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row align-items-center no-gutters" }, [
        _c("div", { staticClass: "col-11" }, [
          _c("ul", { staticClass: "tt-list-badge" }, [
            _c("li", { staticClass: "show-mobile" }, [
              _c("a", { attrs: { href: "#" } }, [
                _c("span", { staticClass: "tt-color03 tt-badge" }, [
                  _vm._v("exchange")
                ])
              ])
            ]),
            _vm._v(" "),
            _c("li", [
              _c("a", { attrs: { href: "#" } }, [
                _c("span", { staticClass: "tt-badge" }, [_vm._v("themeforest")])
              ])
            ]),
            _vm._v(" "),
            _c("li", [
              _c("a", { attrs: { href: "#" } }, [
                _c("span", { staticClass: "tt-badge" }, [_vm._v("elements")])
              ])
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-1 ml-auto show-mobile" }, [
          _c("div", { staticClass: "tt-value" }, [_vm._v("4d")])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tt-col-category" }, [
      _c("span", { staticClass: "tt-color03 tt-badge" }, [_vm._v("exchange")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tt-col-description" }, [
      _c("h6", { staticClass: "tt-title" }, [
        _c("a", { attrs: { href: "page-single-topic.html" } }, [
          _vm._v(
            "\n                       Google snippets wordpress plugin\n                    "
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row align-items-center no-gutters" }, [
        _c("div", { staticClass: "col-11" }, [
          _c("ul", { staticClass: "tt-list-badge" }, [
            _c("li", { staticClass: "show-mobile" }, [
              _c("a", { attrs: { href: "#" } }, [
                _c("span", { staticClass: "tt-color04 tt-badge" }, [
                  _vm._v("pets")
                ])
              ])
            ]),
            _vm._v(" "),
            _c("li", [
              _c("a", { attrs: { href: "#" } }, [
                _c("span", { staticClass: "tt-badge" }, [_vm._v("videohive")])
              ])
            ]),
            _vm._v(" "),
            _c("li", [
              _c("a", { attrs: { href: "#" } }, [
                _c("span", { staticClass: "tt-badge" }, [_vm._v("photodune")])
              ])
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-1 ml-auto show-mobile" }, [
          _c("div", { staticClass: "tt-value" }, [_vm._v("4d")])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tt-col-category" }, [
      _c("span", { staticClass: "tt-color04 tt-badge" }, [_vm._v("pets")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tt-col-description" }, [
      _c("h6", { staticClass: "tt-title" }, [
        _c("a", { attrs: { href: "page-single-topic.html" } }, [
          _vm._v(
            "\n                        How to use Team Listing?\n                    "
          )
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "row align-items-center no-gutters hide-desktope" },
        [
          _c("div", { staticClass: "col-11" }, [
            _c("ul", { staticClass: "tt-list-badge" }, [
              _c("li", { staticClass: "show-mobile" }, [
                _c("a", { attrs: { href: "#" } }, [
                  _c("span", { staticClass: "tt-color09 tt-badge" }, [
                    _vm._v("social")
                  ])
                ])
              ])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-1 ml-auto show-mobile" }, [
            _c("div", { staticClass: "tt-value" }, [_vm._v("5d")])
          ])
        ]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tt-col-category" }, [
      _c("span", { staticClass: "tt-color09 tt-badge" }, [_vm._v("social")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "row align-items-center no-gutters hide-desktope" },
      [
        _c("div", { staticClass: "col-11" }, [
          _c("ul", { staticClass: "tt-list-badge" }, [
            _c("li", { staticClass: "show-mobile" }, [
              _c("a", { attrs: { href: "#" } }, [
                _c("span", { staticClass: "tt-color02 tt-badge" }, [
                  _vm._v("video")
                ])
              ])
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-1 ml-auto show-mobile" }, [
          _c("div", { staticClass: "tt-value" }, [_vm._v("5d")])
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tt-col-category" }, [
      _c("span", { staticClass: "tt-color02 tt-badge" }, [_vm._v("video")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tt-col-description" }, [
      _c("h6", { staticClass: "tt-title" }, [
        _c("a", { attrs: { href: "page-single-topic.html" } }, [
          _vm._v(
            "\n                        Documentation on Glitch package usage?\n                    "
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row align-items-center no-gutters" }, [
        _c("div", { staticClass: "col-11" }, [
          _c("ul", { staticClass: "tt-list-badge" }, [
            _c("li", { staticClass: "show-mobile" }, [
              _c("a", { attrs: { href: "#" } }, [
                _c("span", { staticClass: "tt-color12 tt-badge" }, [
                  _vm._v("arts")
                ])
              ])
            ]),
            _vm._v(" "),
            _c("li", [
              _c("a", { attrs: { href: "#" } }, [
                _c("span", { staticClass: "tt-badge" }, [_vm._v("themeforest")])
              ])
            ]),
            _vm._v(" "),
            _c("li", [
              _c("a", { attrs: { href: "#" } }, [
                _c("span", { staticClass: "tt-badge" }, [_vm._v("elements")])
              ])
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-1 ml-auto show-mobile" }, [
          _c("div", { staticClass: "tt-value" }, [_vm._v("5d")])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tt-col-category" }, [
      _c("span", { staticClass: "tt-color12 tt-badge" }, [_vm._v("arts")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tt-col-description" }, [
      _c("h6", { staticClass: "tt-title" }, [
        _c("a", { attrs: { href: "page-single-topic.html" } }, [
          _vm._v(
            "\n                       Woohoo! You’ve made it. Welcome to the Elite Club\n                    "
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row align-items-center no-gutters" }, [
        _c("div", { staticClass: "col-11" }, [
          _c("ul", { staticClass: "tt-list-badge" }, [
            _c("li", { staticClass: "show-mobile" }, [
              _c("a", { attrs: { href: "#" } }, [
                _c("span", { staticClass: "tt-color04 tt-badge" }, [
                  _vm._v("pets")
                ])
              ])
            ]),
            _vm._v(" "),
            _c("li", [
              _c("a", { attrs: { href: "#" } }, [
                _c("span", { staticClass: "tt-badge" }, [_vm._v("videohive")])
              ])
            ]),
            _vm._v(" "),
            _c("li", [
              _c("a", { attrs: { href: "#" } }, [
                _c("span", { staticClass: "tt-badge" }, [_vm._v("photodune")])
              ])
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-1 ml-auto show-mobile" }, [
          _c("div", { staticClass: "tt-value" }, [_vm._v("5d")])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tt-col-category" }, [
      _c("span", { staticClass: "tt-color04 tt-badge" }, [_vm._v("pets")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }