<template>
    <div>
        
<main id="tt-pageContent" class="tt-offset-small">
    <div class="container">
        <div class="tt-topic-list">
            <div class="tt-list-header">
                <div class="tt-col-topic">Topic</div>
                <div class="tt-col-category">Category</div>
                <div class="tt-col-value hide-mobile">Likes</div>
                <div class="tt-col-value hide-mobile">Replies</div>
                <div class="tt-col-value hide-mobile">Views</div>
                <div class="tt-col-value">Activity</div>
            </div>
            <div class="tt-topic-alert tt-alert-default" role="alert">
              <a href="#" target="_blank">4 new posts</a> are added recently, click here to load them.
            </div>
            <div class="tt-item tt-itemselect">
                <div class="tt-col-avatar">
                    <svg class="tt-icon">
                      <use xlink:href="#icon-ava-k"></use>
                    </svg>
                </div>
                <div class="tt-col-description">
                    <h6 class="tt-title"><a href="page-single-topic.html">
                        <svg class="tt-icon">
                          <use xlink:href="#icon-pinned"></use>
                        </svg>
                        Halloween Costume Contest 2018
                    </a></h6>
                    <div class="row align-items-center no-gutters">
                        <div class="col-11">
                            <ul class="tt-list-badge">
                                <li class="show-mobile"><a href="#"><span class="tt-color01 tt-badge">politics</span></a></li>
                                <li><a href="#"><span class="tt-badge">contests</span></a></li>
                                <li><a href="#"><span class="tt-badge">authors</span></a></li>
                            </ul>
                        </div>
                        <div class="col-1 ml-auto show-mobile">
                            <div class="tt-value">1h</div>
                        </div>
                    </div>
                </div>
                <div class="tt-col-category"><span class="tt-color01 tt-badge">politics</span></div>
                <div class="tt-col-value hide-mobile">985</div>
                <div class="tt-col-value tt-color-select hide-mobile">502</div>
                <div class="tt-col-value hide-mobile">15.1k</div>
                <div class="tt-col-value hide-mobile">1h</div>
            </div>
            <div class="tt-item tt-itemselect">
                <div class="tt-col-avatar">
                    <svg class="tt-icon">
                      <use xlink:href="#icon-ava-l"></use>
                    </svg>
                </div>
                <div class="tt-col-description">
                    <h6 class="tt-title"><a href="page-single-topic.html">
                        <svg class="tt-icon">
                          <use xlink:href="#icon-locked"></use>
                        </svg>
                        We’re removing Envato Credits from Market
                    </a></h6>
                    <div class="row align-items-center no-gutters  hide-desktope">
                        <div class="col-11">
                           <ul class="tt-list-badge">
                               <li class="show-mobile"><a href="#"><span class="tt-color02 tt-badge">video</span></a></li>
                           </ul>
                        </div>
                        <div class="col-1 ml-auto show-mobile">
                            <div class="tt-value">2h</div>
                        </div>
                    </div>
                </div>
                <div class="tt-col-category"><span class="tt-color02 tt-badge">video</span></div>
                <div class="tt-col-value hide-mobile">584</div>
                <div class="tt-col-value tt-color-select  hide-mobile">35</div>
                <div class="tt-col-value hide-mobile">1.3k</div>
                <div class="tt-col-value hide-mobile">2h</div>
            </div>
            <div class="tt-item tt-itemselect">
                <div class="tt-col-avatar">
                    <svg class="tt-icon">
                      <use xlink:href="#icon-ava-d"></use>
                    </svg>
                </div>
                <div class="tt-col-description">
                    <h6 class="tt-title"><a href="page-single-topic.html">
                        Web Hosting Packages for ThemeForest WordPress
                    </a></h6>
                    <div class="row align-items-center no-gutters">
                        <div class="col-11">
                            <ul class="tt-list-badge">
                                <li class="show-mobile"><a href="#"><span class="tt-color03 tt-badge">exchange</span></a></li>
                                <li><a href="#"><span class="tt-badge">themeforest</span></a></li>
                                <li><a href="#"><span class="tt-badge">elements</span></a></li>
                            </ul>
                        </div>
                        <div class="col-1 ml-auto show-mobile">
                            <div class="tt-value">2h</div>
                        </div>
                    </div>
                </div>
                <div class="tt-col-category"><span class="tt-color03 tt-badge">exchange</span></div>
                <div class="tt-col-value  hide-mobile">401</div>
                <div class="tt-col-value tt-color-select  hide-mobile">975</div>
                <div class="tt-col-value  hide-mobile">12.6k</div>
                <div class="tt-col-value hide-mobile">2h</div>
            </div>
            <div class="tt-item">
                <div class="tt-col-avatar">
                    <svg class="tt-icon">
                      <use xlink:href="#icon-ava-c"></use>
                    </svg>
                </div>
                <div class="tt-col-description">
                    <h6 class="tt-title"><a href="page-single-topic.html">
                        Review Queue Changes for VideoHive & PhotoDune
                    </a></h6>
                    <div class="row align-items-center no-gutters">
                        <div class="col-11">
                            <ul class="tt-list-badge">
                                <li class="show-mobile"><a href="#"><span class="tt-color04 tt-badge">pets</span></a></li>
                                <li><a href="#"><span class="tt-badge">videohive</span></a></li>
                                <li><a href="#"><span class="tt-badge">photodune</span></a></li>
                            </ul>
                        </div>
                        <div class="col-1 ml-auto show-mobile">
                            <div class="tt-value">1d</div>
                        </div>
                    </div>
                </div>
                <div class="tt-col-category"><span class="tt-color04 tt-badge">pets</span></div>
                <div class="tt-col-value  hide-mobile">308</div>
                <div class="tt-col-value tt-color-select  hide-mobile">660</div>
                <div class="tt-col-value  hide-mobile">8.3k</div>
                <div class="tt-col-value hide-mobile">1d</div>
            </div>
            <div class="tt-item">
                <div class="tt-col-avatar">
                    <svg class="tt-icon">
                      <use xlink:href="#icon-ava-n"></use>
                    </svg>
                </div>
                <div class="tt-col-description">
                    <h6 class="tt-title"><a href="page-single-topic.html">
                        Does Envato act against the authors of Envato markets?
                    </a></h6>
                    <div class="row align-items-center no-gutters  hide-desktope">
                        <div class="col-11">
                            <ul class="tt-list-badge">
                                <li class="show-mobile"><a href="#"><span class="tt-color05 tt-badge">music</span></a></li>
                            </ul>
                        </div>
                        <div class="col-1 ml-auto show-mobile">
                            <div class="tt-value">1d</div>
                        </div>
                    </div>
                </div>
                <div class="tt-col-category"><span class="tt-color05 tt-badge">music</span></div>
                <div class="tt-col-value hide-mobile">358</div>
                <div class="tt-col-value tt-color-select hide-mobile">68</div>
                <div class="tt-col-value hide-mobile">8.3k</div>
                <div class="tt-col-value hide-mobile">1d</div>
            </div>
            <div class="tt-item">
                <div class="tt-col-avatar">
                   <svg class="tt-icon">
                      <use xlink:href="#icon-ava-h"></use>
                    </svg>
                </div>
                <div class="tt-col-description">
                    <h6 class="tt-title"><a href="page-single-topic.html">
                        <svg class="tt-icon">
                          <use xlink:href="#icon-locked"></use>
                        </svg>
                        We Want to Hear From You! What Would You Like?
                    </a></h6>
                    <div class="row align-items-center no-gutters  hide-desktope">
                        <div class="col-11">
                            <ul class="tt-list-badge">
                                <li class="show-mobile"><a href="#"><span class="tt-color06 tt-badge">movies</span></a></li>
                            </ul>
                        </div>
                        <div class="col-1 ml-auto show-mobile">
                            <div class="tt-value">2d</div>
                        </div>
                    </div>
                </div>
                <div class="tt-col-category"><span class="tt-color06 tt-badge">movies</span></div>
                <div class="tt-col-value hide-mobile">671</div>
                <div class="tt-col-value tt-color-select  hide-mobile">29</div>
                <div class="tt-col-value hide-mobile">1.3k</div>
                <div class="tt-col-value hide-mobile">2d</div>
            </div>
         
            <div class="tt-item">
                <div class="tt-col-avatar">
                   <svg class="tt-icon">
                      <use xlink:href="#icon-ava-t"></use>
                    </svg>
                </div>
                <div class="tt-col-description">
                    <h6 class="tt-title"><a href="page-single-topic.html">
                        Cannot customize my intro
                    </a></h6>
                    <div class="row align-items-center no-gutters">
                        <div class="col-11">
                            <ul class="tt-list-badge">
                                <li class="show-mobile"><a href="#"><span class="tt-color07 tt-badge">video games</span></a></li>
                                <li><a href="#"><span class="tt-badge">videohive</span></a></li>
                                <li><a href="#"><span class="tt-badge">photodune</span></a></li>
                            </ul>
                        </div>
                        <div class="col-1 ml-auto show-mobile">
                            <div class="tt-value">2d</div>
                        </div>
                    </div>
                </div>
                <div class="tt-col-category"><span class="tt-color07 tt-badge">video games</span></div>
                <div class="tt-col-value hide-mobile">364</div>
                <div class="tt-col-value tt-color-select  hide-mobile">36</div>
                <div class="tt-col-value  hide-mobile">982</div>
                <div class="tt-col-value hide-mobile">2d</div>
            </div>
            <div class="tt-item">
                <div class="tt-col-avatar">
                   <svg class="tt-icon">
                      <use xlink:href="#icon-ava-k"></use>
                    </svg>
                </div>
                <div class="tt-col-description">
                    <h6 class="tt-title"><a href="page-single-topic.html">
                        <svg class="tt-icon">
                          <use xlink:href="#icon-verified"></use>
                        </svg>
                        Microsoft Word and Power Point
                    </a></h6>
                    <div class="row align-items-center no-gutters hide-desktope">
                        <div class="col-11">
                            <ul class="tt-list-badge ">
                                <li class="show-mobile"><a href="#"><span class="tt-color08 tt-badge">youtube</span></a></li>
                            </ul>
                        </div>
                        <div class="col-1 ml-auto show-mobile">
                            <div class="tt-value">3d</div>
                        </div>
                    </div>
                </div>
                <div class="tt-col-category"><span class="tt-color08 tt-badge">youtube</span></div>
                <div class="tt-col-value  hide-mobile">698</div>
                <div class="tt-col-value tt-color-select  hide-mobile">78</div>
                <div class="tt-col-value  hide-mobile">2.1k</div>
                <div class="tt-col-value hide-mobile">3d</div>
            </div>
            <div class="tt-item">
                <div class="tt-col-avatar">
                   <svg class="tt-icon">
                      <use xlink:href="#icon-ava-v"></use>
                    </svg>
                </div>
                <div class="tt-col-description">
                    <h6 class="tt-title"><a href="page-single-topic.html">
                        First website template got rejected.
                    </a></h6>
                    <div class="row align-items-center no-gutters  hide-desktope">
                        <div class="col-11">
                            <ul class="tt-list-badge">
                                <li class="show-mobile"><a href="#"><span class="tt-color09 tt-badge">social</span></a></li>
                            </ul>
                        </div>
                        <div class="col-1 ml-auto show-mobile">
                            <div class="tt-value">3d</div>
                        </div>
                    </div>
                </div>
                <div class="tt-col-category"><span class="tt-color09 tt-badge">social</span></div>
                <div class="tt-col-value  hide-mobile">12</div>
                <div class="tt-col-value tt-color-select  hide-mobile">3</div>
                <div class="tt-col-value  hide-mobile">268</div>
                <div class="tt-col-value hide-mobile">3d</div>
            </div>
            <div class="tt-item">
                <div class="tt-col-avatar">
                   <svg class="tt-icon">
                      <use xlink:href="#icon-ava-k"></use>
                    </svg>
                </div>
                <div class="tt-col-description">
                    <h6 class="tt-title"><a href="page-single-topic.html">
                        <svg class="tt-icon">
                          <use xlink:href="#icon-pinned"></use>
                        </svg>
                       Proform or looking for contacting billing department
                    </a></h6>
                    <div class="row align-items-center no-gutters">
                        <div class="col-11">
                            <ul class="tt-list-badge">
                                <li class="show-mobile"><a href="#"><span class="tt-color10 tt-badge">science</span></a></li>
                                <li><a href="#"><span class="tt-badge">contests</span></a></li>
                                <li><a href="#"><span class="tt-badge">authors</span></a></li>
                            </ul>
                        </div>
                        <div class="col-1 ml-auto show-mobile">
                            <div class="tt-value">3d</div>
                        </div>
                    </div>
                </div>
                <div class="tt-col-category"><span class="tt-color10 tt-badge">science</span></div>
                <div class="tt-col-value hide-mobile">274</div>
                <div class="tt-col-value tt-color-select hide-mobile">114</div>
                <div class="tt-col-value  hide-mobile">2.3k</div>
                <div class="tt-col-value hide-mobile">3d</div>
            </div>
            <div class="tt-item">
                <div class="tt-col-avatar">
                   <svg class="tt-icon">
                      <use xlink:href="#icon-ava-y"></use>
                    </svg>
                </div>
                <div class="tt-col-description">
                    <h6 class="tt-title"><a href="page-single-topic.html">
                        <svg class="tt-icon">
                          <use xlink:href="#icon-locked"></use>
                        </svg>
                        Refund for wrongly purchase HTML template
                    </a></h6>
                    <div class="row align-items-center no-gutters hide-desktope">
                        <div class="col-11">
                            <ul class="tt-list-badge">
                                <li class="show-mobile"><a href="#"><span class="tt-color11 tt-badge">entertainment</span></a></li>
                            </ul>
                        </div>
                        <div class="col-1 ml-auto show-mobile">
                            <div class="tt-value">3d</div>
                        </div>
                    </div>
                </div>
                <div class="tt-col-category"><span class="tt-color11 tt-badge">entertainment</span></div>
                <div class="tt-col-value hide-mobile">657</div>
                <div class="tt-col-value tt-color-select hide-mobile">177</div>
                <div class="tt-col-value hide-mobile">2.6k</div>
                <div class="tt-col-value hide-mobile">3d</div>
            </div>
            <div class="tt-item">
                <div class="tt-col-avatar">
                   <svg class="tt-icon">
                      <use xlink:href="#icon-ava-s"></use>
                    </svg>
                </div>
                <div class="tt-col-description">
                    <h6 class="tt-title"><a href="page-single-topic.html">
                       Why all my affiliate balance is pending?
                    </a></h6>
                    <div class="row align-items-center no-gutters">
                        <div class="col-11">
                            <ul class="tt-list-badge">
                                <li class="show-mobile"><a href="#"><span class="tt-color03 tt-badge">exchange</span></a></li>
                                <li><a href="#"><span class="tt-badge">themeforest</span></a></li>
                                <li><a href="#"><span class="tt-badge">elements</span></a></li>
                            </ul>
                        </div>
                        <div class="col-1 ml-auto show-mobile">
                            <div class="tt-value">4d</div>
                        </div>
                    </div>
                </div>
                <div class="tt-col-category"><span class="tt-color03 tt-badge">exchange</span></div>
                <div class="tt-col-value hide-mobile">37</div>
                <div class="tt-col-value tt-color-select hide-mobile">31</div>
                <div class="tt-col-value hide-mobile">257</div>
                <div class="tt-col-value hide-mobile">4d</div>
            </div>
            <div class="tt-item">
                <div class="tt-col-avatar">
                   <svg class="tt-icon">
                      <use xlink:href="#icon-ava-l"></use>
                    </svg>
                </div>
                <div class="tt-col-description">
                    <h6 class="tt-title"><a href="page-single-topic.html">
                       Google snippets wordpress plugin
                    </a></h6>
                    <div class="row align-items-center no-gutters">
                        <div class="col-11">
                            <ul class="tt-list-badge">
                                <li class="show-mobile"><a href="#"><span class="tt-color04 tt-badge">pets</span></a></li>
                                <li><a href="#"><span class="tt-badge">videohive</span></a></li>
                                <li><a href="#"><span class="tt-badge">photodune</span></a></li>
                            </ul>
                        </div>
                        <div class="col-1 ml-auto show-mobile">
                            <div class="tt-value">4d</div>
                        </div>
                    </div>
                </div>
                <div class="tt-col-category"><span class="tt-color04 tt-badge">pets</span></div>
                <div class="tt-col-value hide-mobile">987</div>
                <div class="tt-col-value tt-color-select hide-mobile">271</div>
                <div class="tt-col-value hide-mobile">3.8k</div>
                <div class="tt-col-value hide-mobile">4d</div>
            </div>
            <div class="tt-item">
                <div class="tt-col-avatar">
                   <svg class="tt-icon">
                      <use xlink:href="#icon-ava-n"></use>
                    </svg>
                </div>
                <div class="tt-col-description">
                    <h6 class="tt-title"><a href="page-single-topic.html">
                        How to use Team Listing?
                    </a></h6>
                    <div class="row align-items-center no-gutters hide-desktope">
                        <div class="col-11">
                            <ul class="tt-list-badge">
                                <li class="show-mobile"><a href="#"><span class="tt-color09 tt-badge">social</span></a></li>
                            </ul>
                        </div>
                        <div class="col-1 ml-auto show-mobile">
                            <div class="tt-value">5d</div>
                        </div>
                    </div>
                </div>
                <div class="tt-col-category"><span class="tt-color09 tt-badge">social</span></div>
                <div class="tt-col-value hide-mobile">324</div>
                <div class="tt-col-value tt-color-select hide-mobile">163</div>
                <div class="tt-col-value hide-mobile">2.3k</div>
                <div class="tt-col-value hide-mobile">5d</div>
            </div>
            <div class="tt-item">
                <div class="tt-col-avatar">
                   <svg class="tt-icon">
                      <use xlink:href="#icon-ava-r"></use>
                    </svg>
                </div>
                <div class="tt-col-description">
                    <h6 class="tt-title"><a href="page-single-topic.html">
                        <svg class="tt-icon">
                          <use xlink:href="#icon-locked"></use>
                        </svg>
                        Can’t change image on main page of Coaching Theme
                    </a></h6>
                    <div class="row align-items-center no-gutters hide-desktope">
                        <div class="col-11">
                            <ul class="tt-list-badge">
                                <li class="show-mobile"><a href="#"><span class="tt-color02 tt-badge">video</span></a></li>
                            </ul>
                        </div>
                        <div class="col-1 ml-auto show-mobile">
                            <div class="tt-value">5d</div>
                        </div>
                    </div>
                </div>
                <div class="tt-col-category"><span class="tt-color02 tt-badge">video</span></div>
                <div class="tt-col-value hide-mobile">879</div>
                <div class="tt-col-value tt-color-select hide-mobile">237</div>
                <div class="tt-col-value hide-mobile">4.5k</div>
                <div class="tt-col-value hide-mobile">5d</div>
            </div>
            <div class="tt-item">
                <div class="tt-col-avatar">
                   <svg class="tt-icon">
                      <use xlink:href="#icon-ava-b"></use>
                    </svg>
                </div>
                <div class="tt-col-description">
                    <h6 class="tt-title"><a href="page-single-topic.html">
                        Documentation on Glitch package usage?
                    </a></h6>
                    <div class="row align-items-center no-gutters">
                        <div class="col-11">
                            <ul class="tt-list-badge">
                                <li class="show-mobile"><a href="#"><span class="tt-color12 tt-badge">arts</span></a></li>
                                <li><a href="#"><span class="tt-badge">themeforest</span></a></li>
                                <li><a href="#"><span class="tt-badge">elements</span></a></li>
                            </ul>
                        </div>
                        <div class="col-1 ml-auto show-mobile">
                            <div class="tt-value">5d</div>
                        </div>
                    </div>
                </div>
                <div class="tt-col-category"><span class="tt-color12 tt-badge">arts</span></div>
                <div class="tt-col-value hide-mobile">726</div>
                <div class="tt-col-value tt-color-select hide-mobile">246</div>
                <div class="tt-col-value hide-mobile">7.6k</div>
                <div class="tt-col-value hide-mobile">5d</div>
            </div>
            <div class="tt-item">
                <div class="tt-col-avatar">
                   <svg class="tt-icon">
                      <use xlink:href="#icon-ava-a"></use>
                    </svg>
                </div>
                <div class="tt-col-description">
                    <h6 class="tt-title"><a href="page-single-topic.html">
                       Woohoo! You’ve made it. Welcome to the Elite Club
                    </a></h6>
                    <div class="row align-items-center no-gutters">
                        <div class="col-11">
                            <ul class="tt-list-badge">
                                <li class="show-mobile"><a href="#"><span class="tt-color04 tt-badge">pets</span></a></li>
                                <li><a href="#"><span class="tt-badge">videohive</span></a></li>
                                <li><a href="#"><span class="tt-badge">photodune</span></a></li>
                            </ul>
                        </div>
                        <div class="col-1 ml-auto show-mobile">
                            <div class="tt-value">5d</div>
                        </div>
                    </div>
                </div>
                <div class="tt-col-category"><span class="tt-color04 tt-badge">pets</span></div>
                <div class="tt-col-value hide-mobile">674</div>
                <div class="tt-col-value tt-color-select hide-mobile">128</div>
                <div class="tt-col-value hide-mobile">1.3k</div>
                <div class="tt-col-value hide-mobile">5d</div>
            </div>
            <div class="tt-row-btn">
                <button type="button" class="btn-icon js-topiclist-showmore">
                    <svg class="tt-icon">
                      <use xlink:href="#icon-load_lore_icon"></use>
                    </svg>
                </button>
            </div>
        </div>
    </div>
</main>
    </div>
</template>

<script>
    export default {
        components: {},
        mounted(){
            if(this.$route.query.reload)
                window.location = window.location.pathname;

            axios.get('/api/dashboard')
                .then(response => response.data)
                .then(response => {
                    this.users = response.users;
                    this.today_registered_users = response.today_registered_users;
                    this.weekly_registered_users = response.weekly_registered_users;
                    this.monthly_registered_users = response.monthly_registered_users;
                    this.activity_logs = response.activity_logs;
                    this.todos = response.todos;
                })
                .catch(error => {
                    helper.showDataErrorMsg(error);
                })

            axios.get('/api/user/preference/pre-requisite')
                .then(response => response.data)
                .then(response => {
                    this.color_themes = response.color_themes;
                    this.directions = response.directions;
                    this.sidebar = response.sidebar;
                    this.locales = response.locales;
                })
                .catch(error => {
                    helper.showDataErrorMsg(error);
                })
        },
        methods: {
            getStatus(todo){
                return todo.status ? ('<span class="label label-success">'+i18n.todo.complete+'</span>') : ('<span class="label label-danger">'+i18n.todo.incomplete+'</span>') ;
            },
            hasRole(role){
                return helper.hasRole(role);
            },
            logout(){
                helper.logout().then(() => {
                    this.$store.dispatch('resetAuthUserDetail');
                    this.$router.push('/login')
                })
            },
            updatePreference(){
                this.preferenceForm.post('/api/user/preference')
                    .then(response => {
                        toastr.success(response.message);

                        $('#theme').attr('href','/css/colors/'+this.preferenceForm.color_theme+'.css');

                        if(this.user_preference.direction != this.preferenceForm.direction || this.user_preference.sidebar != this.preferenceForm.sidebar || this.user_preference.locale != this.preferenceForm.locale)
                            location.reload();
                    })
                    .catch(error => {
                        helper.showErrorMsg(error);
                    })
            }
        },
        data() {
            return {
                users: 0,
                today_registered_users: 0,
                weekly_registered_users: 0,
                monthly_registered_users: 0,
                activity_logs: {},
                todos: {},
                color_themes: [],
                directions: [],
                sidebar: [],
                locales: [],
                preferenceForm: new Form({
                    color_theme: helper.getConfig('user_color_theme') || helper.getConfig('color_theme'),
                    direction: helper.getConfig('user_direction') || helper.getConfig('direction'),
                    locale: helper.getConfig('user_locale') || helper.getConfig('locale'),
                    sidebar: helper.getConfig('user_sidebar') || helper.getConfig('sidebar')
                },false),
                user_preference: {
                    color_theme: helper.getConfig('user_color_theme') || helper.getConfig('color_theme'),
                    direction: helper.getConfig('user_direction') || helper.getConfig('direction'),
                    locale: helper.getConfig('user_locale') || helper.getConfig('locale'),
                    sidebar: helper.getConfig('user_sidebar') || helper.getConfig('sidebar')
                }
            }
        },
        computed: {
        },
        filters: {
          momentDateTime(date) {
            return helper.formatDateTime(date);
          },
          moment(date) {
            return helper.formatDate(date);
          }
        },
    }
</script>
<style>
    .shw-rside{
        width: 500px;
    }
</style>