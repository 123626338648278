<template>
    <div>
        <nav class="panel-menu" id="mobile-menu">
        <ul>

        </ul>
        <div class="mm-navbtn-names">
            <div class="mm-closebtn">
                Close
                <div class="tt-icon">
                    <svg>
                        <use xlink:href="#icon-cancel"></use>
                    </svg>
                </div>
            </div>
            <div class="mm-backbtn">Back</div>
        </div>
    </nav>
    <header id="tt-header">
        <div class="container">
            <div class="row tt-row no-gutters">
                <div class="col-auto">
                    <!-- toggle mobile menu -->
                    <a class="toggle-mobile-menu" href="#">
                        <svg class="tt-icon">
                        <use xlink:href="#icon-menu_icon"></use>
                        </svg>
                    </a>
                    <!-- /toggle mobile menu -->
                    <!-- logo -->
                    <div class="tt-logo">
                        <router-link to="/">
                            <img :src="getMainLogo" alt="">
                        </router-link>
                    </div>
                    <!-- /logo -->
                    <!-- desctop menu -->
                    <div class="tt-desktop-menu">
                        <nav>
                            <ul>
                                <li><router-link to="/categories">Categories</router-link></li> 
                            </ul>
                        </nav>
                    </div>
                    <!-- /desctop menu -->
                    <!-- tt-search -->
                    <div class="tt-search">
                        <!-- toggle -->
                        <button class="tt-search-toggle" data-toggle="modal" data-target="#modalAdvancedSearch">
                        <svg class="tt-icon">
                            <use xlink:href="#icon-search"></use>
                            </svg>
                        </button>
                        <!-- /toggle -->
                        <form class="search-wrapper">
                            <div class="search-form">
                                <input type="text" class="tt-search__input" placeholder="Search">
                                <button class="tt-search__btn" type="submit">
                                <svg class="tt-icon">
                                    <use xlink:href="#icon-search"></use>
                                    </svg>
                                </button>
                                <button class="tt-search__close">
                                <svg class="tt-icon">
                                    <use xlink:href="#cancel"></use>
                                    </svg>
                                </button>
                            </div>
                            <div class="search-results">
                                <div class="tt-search-scroll">
                                    <ul>
                                        <li>
                                            <a href="page-single-topic.html">
                                                <h6 class="tt-title">Rdr2 secret easter eggs</h6>
                                                <div class="tt-description">
                                                Here’s what I’ve found in Red Dead Redem..
                                                </div>
                                            </a>
                                        </li>
                                        <li>
                                        <a href="page-single-topic.html">
                                                <h6 class="tt-title">Top 10 easter eggs in Red Dead Rede..</h6>
                                                <div class="tt-description">
                                                    You can find these easter eggs in Red Dea..
                                                </div>
                                            </a>
                                        </li>
                                        <li>
                                        <a href="page-single-topic.html">
                                                <h6 class="tt-title">Red Dead Redemtion: Arthur Morgan..</h6>
                                                <div class="tt-description">
                                                    Here’s what I’ve found in Red Dead Redem..
                                                </div>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="page-single-topic.html">
                                                <h6 class="tt-title">Rdr2 secret easter eggs</h6>
                                                <div class="tt-description">
                                                Here’s what I’ve found in Red Dead Redem..
                                                </div>
                                            </a>
                                        </li>
                                        <li>
                                        <a href="page-single-topic.html">
                                                <h6 class="tt-title">Top 10 easter eggs in Red Dead Rede..</h6>
                                                <div class="tt-description">
                                                    You can find these easter eggs in Red Dea..
                                                </div>
                                            </a>
                                        </li>
                                        <li>
                                        <a href="page-single-topic.html">
                                                <h6 class="tt-title">Red Dead Redemtion: Arthur Morgan..</h6>
                                                <div class="tt-description">
                                                    Here’s what I’ve found in Red Dead Redem..
                                                </div>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <button type="button" class="tt-view-all" data-toggle="modal" data-target="#modalAdvancedSearch">Advanced Search</button>
                            </div>
                        </form>
                    </div>
                    <!-- /tt-search -->
                </div>
                <div class="col-auto ml-auto">
                    <div class="tt-user-info d-flex justify-content-center">
                        <a href="#" class="tt-btn-icon">
                            <i class="tt-icon"><svg><use xlink:href="#icon-notification"></use></svg></i>
                        </a>
                        <div class="tt-avatar-icon tt-size-md">
                            <i class="tt-icon"><svg><use xlink:href="#icon-ava-a"></use></svg></i>
                        </div>
                        <div class="">
                            <h4>{{getAuthUser('full_name')}}</h4> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
    <a href="page-create-topic.html" class="tt-btn-create-topic">
        <span class="tt-icon">
            <svg>
            <use xlink:href="#icon-create_new"></use>
            </svg>
        </span>
    </a>

    </div>
</template>

<script>
    export default {
        mounted() {
        },
        methods : {
            logout(){
                helper.logout().then(() => {
                    this.$store.dispatch('resetAuthUserDetail');
                    this.$router.push('/login')
                })
            },
            getAuthUser(name){
                return helper.getAuthUser(name);
            },
            getConfig(name){
                return helper.getConfig(name);
            },
            hasPermission(permission){
                return helper.hasPermission(permission);
            }
        },
        computed: {
            getMainLogo(){
                if(helper.getConfig('main_logo'))
                    return '/'+helper.getConfig('main_logo');
                else
                    return '/images/default_main_logo.png';
            },
            getSidebarLogo(){
                if(helper.getConfig('sidebar_logo'))
                    return '/'+helper.getConfig('sidebar_logo');
                else
                    return '/images/default_sidebar_logo.png';
            }
        }
    }
</script>
